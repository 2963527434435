import React, { useState ,useEffect} from "react";
import logo from "../Logo1.svg";
import small from "../logo_short.png";
import styles from "./Header.module.css";
import { useSelector } from 'react-redux';
import { useUserContext } from "../contexts/UserProvider";
import {  useNavigate } from "react-router-dom";
import { Callout, Persona, PersonaSize } from "@fluentui/react";
import { useLocationPath } from "../hooks/useLocationPath";
import { axiosPrivateCall } from "../constants";
import { useDispatch } from "react-redux";
import { resetDashboardData } from "../redux_library/slice/dashboarddata.slice";
import { resetBDEDashboardData } from "../redux_library/slice/bdeDashboardData.slice";
import AccountSettingModal from "../pages/AccountSettingModal";
import { MessageBar, MessageBarType } from "@fluentui/react";

const profileCalloutStyles = {
  calloutMain: {
    width: 300,
    padding: 14,
  },
};

const messageBarStyles = {
  content: {
    maxWidth: 620,
    minWidth: 450,
  },
};

const defaultUser = {
  text: "Maor Sharett",
  imageInitials: "",
  secondaryText: "maorsharett@gmail.com",
  showSecondaryText: true,
};

const miniPersonaStyles = {
  root: {
    cursor: "pointer",
    alignItems: "flex-start",
    height: 24,
  },
  details: {
    display: "none",
  },
};

const mainPersonaStyles = {
  tertiaryText: {
    color: "#0078D4",
  },
};

function Header(props) {
  const isEdit = useSelector((state) => state.isEdit); 


  const { isCollapsed } = useUserContext();
  const [showProfileCallout, setShowProfileCallout] = useState(false);
  const [showMessageBar, setShowMessageBar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);

  const navigate = useNavigate();
  const { mainPath, subPath } = useLocationPath();
  console.log(mainPath,subPath,"path")
  const [user, setUser] = useState({});
  let Breadcrumb;
  const token = localStorage.getItem("token");
  let base64Url = token.split(".")[1];
  let decodedValue = JSON.parse(window.atob(base64Url));
  const dispatch = useDispatch();


  useEffect(() => {
    getEmployeeData();
  }, []);

  function getEmployeeData() {
    axiosPrivateCall
      .get(`/api/v1/employee/getEmployeeDetails?employee_id=${decodedValue.user_id}`)
      .then((res) => {
        setUser(res.data);
        localStorage.setItem("first_name", res.data.first_name);
        localStorage.setItem("last_name", res.data.last_name);
        localStorage.setItem("demand_creator", res.data.demand_creator);
        localStorage.setItem("employee_list_data", JSON.stringify(res.data));
      })
      .catch((e) => {
        console.log(e);
      });
  }

  let currentUser = user.first_name
    ? {
        text: `${user.first_name} ${user.last_name}`,
        imageInitials: `${user?.first_name?.charAt(0)}${user?.last_name?.charAt(0)}`.toUpperCase(),
        secondaryText: `${user.email}`,
        showSecondaryText: true,
      }
    : { ...defaultUser };

  if (mainPath === "dashboard") {
    Breadcrumb = { mainpath: "Dashboard" };
  }  else if (mainPath === "bdedashboard") {
    Breadcrumb = { mainpath: "BDE Dashboard" };
  } else if (mainPath === "target") {
    if (subPath === "managetarget") {
      Breadcrumb = { mainpath: "Target", subpath: "Manage Targets" };
    }
    if (subPath === "bdmanagetarget") {
      Breadcrumb = { mainpath: "Target", subpath: "Manage Targets" };
    } else if (subPath === "bdetarget") {
      Breadcrumb = { mainpath: "Target", subpath: "My Targets" };
    } else if (subPath === "mytarget") {
      Breadcrumb = { mainpath: "Target", subpath: "My Targets" };
    }
  } else if (mainPath === "demand") {
    if (subPath === "adddemand") {
      Breadcrumb = { mainpath: "Demand", subpath: "Add Demand" };
    } else if (subPath === "managedemands") {
      Breadcrumb = { mainpath: "Demand", subpath: "Manage Demands" };
    } else if (subPath === "mydemands") {
      Breadcrumb = { mainpath: "Demand", subpath: "My Demands" };
    } else if (subPath === "demandstatus") {
      Breadcrumb = { mainpath: "Demand", subpath: "Demand Status" };
    } else if (subPath === "viewandeditdemand") {
      Breadcrumb = { mainpath: "Demand", subpath: isEdit ? "Edit Demand":"View Demand" };
    } else if (subPath === "viewdemand") {
      Breadcrumb = { mainpath: "Demand", subpath: "View Demand" };
    }
  } else if (mainPath === "submission") {
    if (subPath === "addsubmission") {
      Breadcrumb = { mainpath: "Submission", subpath: "Add Submission" };
    } else if (subPath === "submissionapproval") {
      Breadcrumb = { mainpath: "Submission", subpath: "Submission Approval" };
    } else if (subPath === "managesubmissions") {
      Breadcrumb = { mainpath: "Submission", subpath: "Manage Submissions" };
    } else if (subPath === "tracksubmission") {
      Breadcrumb = { mainpath: "Submission", subpath: "Track Submission" };
    } else if (subPath === "viewsubmission") {
      Breadcrumb = { mainpath: "Submission", subpath: "View Submission" };
    } else if (subPath === "mysubmissions") {
      Breadcrumb = { mainpath: "Submission", subpath: "My Submissions" };
    }
  }
  else if (mainPath === "leads") {
    if (subPath === "leadsall") {
      Breadcrumb = { mainpath: "Lead", subpath: " Lead Listings" };
    }
    if (subPath === "AddLeadModal") {
      Breadcrumb = { mainpath: "Lead", subpath: "Add Leads" };
    } else if (subPath === "leadsactive") {
      Breadcrumb = { mainpath: "Lead", subpath: "Manage Leads" };
    } else if (subPath === "leadspassive") {
      Breadcrumb = { mainpath: "Lead", subpath: "Leads Bucket" };
    }
  } else if (mainPath === "candidatelibrary") {
    if (subPath === "mycandidate") {
      Breadcrumb = { mainpath: "Candidate Library", subpath: "My Candidates" };
    } else if (subPath === "candidatehistory") {
      Breadcrumb = { mainpath: "Candidate Library", subpath: "Candidate History" };
    } else if (subPath === "managecandidates") {
      Breadcrumb = {
        mainpath: "Candidate Library", subpath: "Manage Candidates",
      };
    } else if (subPath === "viewcandidate") {
      Breadcrumb = { mainpath: "Candidate Library", subpath: "View Candidate" };
    } else if (subPath === "editcandidate") {
      Breadcrumb = { mainpath: "Candidate Library", subpath: "Edit Candidate" };
    }
  } else if (mainPath === "employee") {
    if (subPath === "addemployee") {
      Breadcrumb = { mainpath: "Employee", subpath: "Add Employee" };
    } else if (subPath === "Manageemployee") {
      Breadcrumb = { mainpath: "Employee", subpath: "Manage Employees" };
    } else if (subPath === "manageemployee") {
      Breadcrumb = { mainpath: "Employee", subpath: "Manage Employees" };
    } else if (subPath === "viewandeditemployee") {
      Breadcrumb = { mainpath: "Employee", subpath: isEdit ? "Edit Employee" : "View Employee" };
    }
  } else if (mainPath === "managedeals") {
    if (subPath === "manageopportunity") {
      Breadcrumb = { mainpath: "Opportunity", subpath: "Manage Opportunities" };
    } else if (subPath === "viewandeditopportunity") {
      Breadcrumb = { mainpath: "Opportunity", subpath:isEdit ? "Edit Opportunity" : "View Opportunity"};
    }
  }
  else if (mainPath === "Interview") {
    if (subPath === "InterviewListing") {
      Breadcrumb = { mainpath: "Interview", subpath: "My Interviews" };
    }
  } else if (mainPath === "masterlist") {
    if (subPath === "manageclient") {
      Breadcrumb = { mainpath: "Master List", subpath: "Manage Clients" };
    } else if (subPath === "viewclient") {
      Breadcrumb = { mainpath: "Master List", subpath: "View Client" };
    } else if (subPath === "editclient") {
      Breadcrumb = { mainpath: "Master List", subpath: "Edit Client" };
    } else if (subPath === "manageskill") {
      Breadcrumb = { mainpath: "Master List", subpath: "Manage Skillset" };
    } else if (subPath === "editskill") {
      Breadcrumb = { mainpath: "Master List", subpath: "Edit Skill" };
    } else if (subPath === "managevendor") {
      Breadcrumb = { mainpath: "Master List", subpath: "Manage Vendor" };
    } else if (subPath === "editvendor") {
      Breadcrumb = { mainpath: "Master List", subpath: "Edit Vendor" };
    }
  } 
  else if (mainPath === "reports") {
    if (subPath === "submissionreport") {
      Breadcrumb = { mainpath: "Report", subpath: "Submission Reports" };
    } else if (subPath === "accountmanager") {
      Breadcrumb = { mainpath: "Report", subpath: "Account Manager" };
    } else if (subPath === "leaddemand") {
      Breadcrumb = { mainpath: "Report", subpath: "Lead Demand" };
    } else if (subPath === "clientreport") {
      Breadcrumb = { mainpath: "Report", subpath: "Client Reports" };
    } else if (subPath === "subvendorsubmission") {
      Breadcrumb = { mainpath: "Report", subpath: "Sub-vendor Submission" };
    } else if (subPath === "clientreportcount") {
      Breadcrumb = { mainpath: "Report", subpath: "Client Report Count" };
    }
  }

  const signOutHandler = () => {
    axiosPrivateCall
      .post("/api/v1/employee/logoutEmployee", {})
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("Demand_id_mail");
        localStorage.removeItem("submissionPath_mail");
        dispatch(resetDashboardData());
        dispatch(resetBDEDashboardData());
      })
      .catch((e) => console.log(e));

    navigate("/");
  };

  useEffect(() => {
    if (isSubmitSuccess) {
      setTimeout(() => {
        setSubmitSuccess(false);
      }, 2000);
    }
  }, [isSubmitSuccess]);

  return (
    <>
      {isModalOpen && (
        <AccountSettingModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isSubmitSuccess={isSubmitSuccess}
          setSubmitSuccess={setSubmitSuccess}
        />
      )}

      <div className={styles.container}>
        {isCollapsed ? (
          <div className={styles.logo_small}>
            <img src={small} alt="Logo"/>
          </div>
        ) : (
          <div className={styles.logo_container}>
            <img src={logo} alt="Logo"/>
          </div>
        )}

<div className={isCollapsed ? styles.header_container1 : styles.header_container}>
          <div>
            {Breadcrumb ? (
              Breadcrumb.subpath ? (
                <div className={styles.breadContainer}>
                  <span className={styles.breadcrumbs} onClick={() => navigate("/dashboard")}>
                    <p>{Breadcrumb.mainpath}</p>
                  </span>{" "}
                  /{" "}
                  <span className={styles.breadcrumbs}>
                    <p>{Breadcrumb?.subpath}</p>
                  </span>{" "}
                </div>
              ) : (
                <div className={styles.breadContainer}>
                  <span className={styles.breadcrumbs}>
                    <p>{Breadcrumb.mainpath}</p>
                  </span>
                </div>
              )
            ) : null}
          </div>


          {isSubmitSuccess && (
            <div>
              <MessageBar
                onDismiss={() => setShowMessageBar(!showMessageBar)}
                styles={messageBarStyles}
                dismissButtonAriaLabel="Close"
                messageBarType={MessageBarType.success}
              >
                Employee updated successfully!
              </MessageBar>
            </div>
          )}

          <div id="profileId" className={styles.user} onClick={() => setShowProfileCallout(!showProfileCallout)}>
            <Persona {...currentUser} size={PersonaSize.size24} styles={miniPersonaStyles} />
            {showProfileCallout && (
              <Callout
                styles={profileCalloutStyles}
                onDismiss={() => setShowProfileCallout(!showProfileCallout)}
                target={"#profileId"}
                isBeakVisible={false}
                coverTarget
              >
                <div className={styles.profile_callout_container}>
                  <div onClick={signOutHandler} className={styles.profile_signout_title}>
                    Sign Out
                  </div>
                  <div className={styles.profile_callout_content}>
                    <div>
                      <Persona
                        {...currentUser}
                        text={currentUser.text}
                        secondaryText={currentUser.secondaryText}
                        size={PersonaSize.size72}
                        styles={mainPersonaStyles}
                      >
                        <div
                          className={styles.profile_callout_content_account_settings}
                          onClick={() => setIsModalOpen(true)}
                        >
                          Account settings
                        </div>
                      </Persona>
                    </div>
                  </div>
                </div>
              </Callout>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
export const ISOdateToCustomDate =(value) =>{
	const dateFormat = new Date(value);
	let year = dateFormat.getFullYear();
	let month = dateFormat.getMonth()+1;
	let date = dateFormat.getDate();

	if (date < 10) {
		date = '0' + date;
	}
	if (month < 10) {
		month = '0' + month;
	}
	return date + '/' + month + '/' + year;

}


export const toLowerCaseUnderScore  = (str) =>{
  
	return str.split(' ').join('_').toLowerCase()

} 

// export function calcTotalExp(Arr) {
// 	let total = { years: 0, months: 0 };
  
// 	Arr.map((detail, index) => {
// 	  console.log(detail.start_date, detail.end_date, "detail.start_date");
	
// 	  function parseDate(dateString) {
// 		if (dateString.includes('/')) {
// 		  let [day, month, year] = dateString.split('/');
// 		  return new Date(`${year}-${month}-${day}`);
// 		} else {
// 		  return new Date(dateString);
// 		}
// 	  }
	
// 	  let startDate = parseDate(detail.start_date);
// 	  let endDate = parseDate(detail.end_date);
// 	  console.log(startDate, endDate, "parsed dates");
	
// 	  if (isNaN(startDate) || isNaN(endDate)) {
// 		console.error('Invalid date format:', detail.start_date, detail.end_date);
// 		return;
// 	  }
	
// 	  let startYear = startDate.getFullYear();
// 	  let endYear = endDate.getFullYear();
// 	  let startMonth = startDate.getMonth() + 1;
// 	  let endMonth = endDate.getMonth() + 1;
	
// 	  total.years += (endYear - startYear);
// 	  total.months += (endMonth - startMonth);
	
// 	  if (total.months >= 12) {
// 		total.years += Math.floor(total.months / 12);
// 		total.months = total.months % 12;
// 	  }
// 	});
	
// 	console.log(`Total: ${total.years} years and ${total.months} months`);
// 	return total;
//   }
  


export function calcTotalExp(Arr) {
	let total = { years: 0, months: 0 };
  
	if (Array.isArray(Arr) && Arr.length > 0) {
		Arr.map((detail, index) => {
		  if (!detail || !detail.start_date || !detail.end_date) {
			console.error('Missing or invalid data:', detail);
			return; // Skip this iteration if `detail`, `start_date`, or `end_date` is null/undefined
		  }
	  
		  console.log(detail.start_date, detail.end_date, "detail.start_date");
	  
		  // Function to parse date
		  function parseDate(dateString) {
			if (!dateString) return NaN; // Ensure dateString is valid
			if (dateString.includes('/')) {
			  let [day, month, year] = dateString.split('/');
			  return new Date(`${year}-${month}-${day}`);
			} else {
			  return new Date(dateString);
			}
		  }
	  
		  let startDate = parseDate(detail.start_date);
		  let endDate = parseDate(detail.end_date);
		  console.log(startDate, endDate, "parsed dates");
	  
		  if (isNaN(startDate) || isNaN(endDate)) {
			console.error('Invalid date format:', detail.start_date, detail.end_date);
			return; // Skip if dates are invalid
		  }
	  
		  let startYear = startDate.getFullYear();
		  let endYear = endDate.getFullYear();
		  let startMonth = startDate.getMonth() + 1;
		  let endMonth = endDate.getMonth() + 1;
	  
		  // Update total years and months
		  total.years += (endYear - startYear);
		  total.months += (endMonth - startMonth);
	  
		  // Adjust for months overflow
		  if (total.months >= 12) {
			total.years += Math.floor(total.months / 12);
			total.months = total.months % 12;
		  }
		});
	  } else {
		console.error('Array is either empty or not an array.');
	  }
	  
	
	console.log(`Total: ${total.years} years and ${total.months} months`);
	return total;
  }
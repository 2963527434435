import React, { useState } from "react";
import NoDataFound from "../assets/Nodatafound.png"
import styles from "./ManageEmployee.module.css"
import { PrimaryButton, SearchBox, FontIcon, mergeStyles, Dropdown } from '@fluentui/react';
import { DefaultButton, Callout, DirectionalHint } from '@fluentui/react';
import { Shimmer } from '@fluentui/react';
import AddDemandModal from "./AddDemandModal";
import AddSubmissionModal from "./AddSubmissionModal";
import AssignDemandModal from "./AssignDemandModal";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { useEffect } from "react";
import { axiosPrivateCall } from "../constants";
import { ISOdateToCustomDate } from "../utils/helpers";
import { useNavigate, useSearchParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { DeletePopup } from "../components/DeletePopup";
import { debounce } from 'lodash';
import { Spinner, SpinnerSize } from "@fluentui/react";
import ViewAndEditDemand from "./ViewAndEditDemand";
const addIcon = { iconName: 'Add' };
const searchIcon = { iconName: 'Search' };


const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: '0 10px',
  color: '#999DA0',
  cursor: 'pointer'
});

const iconClass1 = mergeStyles({
  fontSize: 12,
  height: 12,
  width: 12,
  margin: '0 ',
  color: '#999DA0',
});


const messageBarStyles = {
  root: {
    maxWidth: '250px',    
    marginLeft: '1px',   
  },
};

const calloutBtnStyles = {
  root: {
    border: 'none',
    padding: '0px 10px',
    textAlign: 'left',
    height: '20px'

  }
}
const CalloutNameStyles = {
  calloutMain: {
    background: '#EDF2F6',
    padding: '2',
  },
}
const dropdownStyles = {
  dropdown: { width: 200 },
};

const options = [
  { key: 'DemandId', text: 'Demand Id' },
  { key: 'job_title', text: 'Requirement' },
  { key: 'skillset', text: 'Skill' },
];


let items = Array(4).fill(null);
function DemandListing() {
  const [showPopup, setShowPopup] = useState(false)
  const [isModalDemandOpen,setIsModalDemandOpen]=useState(false)
  const [updateId, setUpdateId] = useState('')
  const [deleteId, setDeleteID] = useState('')
  const [isSubmitDel, setSubmitDel] = useState(false);
  const [showMessageBar, setShowMessageBar] = useState(false);
  const [showSubStauts, setShowSubStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subIsModalOpen, setSubIsModalOpen] = useState(false);
  const [showAssignDemandmodal, setShowAssignDemandModal] = useState(false);
  const [demandList, setDemandList] = useState([]);
  const [demandId, setDemandId] = useState('');
  const [assignDemandId, setAssignDemandId] = useState();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isUserSearching, setIsUserSearching] = useState(false)
  const [fetchOptions, setFetchOptions] = useState({
    skip: 0,
    limit: 15,
    sort_field: 'createdAt',
    sort_type: -1,
    search_field: ''
  })
  const [rowId, setRowId] = useState('');
  const [updateCallout, setUpdateCallout] = useState(false);
  const [hasMore, setHasMore] = useState(true)
  const navigateTo = useNavigate();
  const demandCreator = localStorage.getItem("demand_creator")
  const [hoverCallout, setHoverCallout] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const userId = searchParams.get("user_id");



  const columns = [
    {
    columnKey: 'Demand ID',
     label: 'Demand ID',
  }, {
    columnKey: 'Requirement',
    label: 'Requirement',
  }, 
  {
    columnKey: 'createdAt',
    label: 'Received Date',
  }, 
 {
    columnKey: 'Client',
    label: 'Client'
  },
  {
    columnKey: 'Due Date',
    label: 'Due Date'
  }
  ,{
    columnKey: 'Min Experience',
    label: 'Min Experience',
  },{
    columnKey: 'Primary Skill',
    label: 'Primary Skill '
  },{
    columnKey: 'Created By',
    label: 'Created By'
  },{
    columnKey: 'Submission Count',
    label: 'Submission Count'
  },{
    columnKey: 'Status',
    label: 'Status'
  },{
    columnKey: 'More Options',
    label: ' '
}];

useEffect(() => {
  if (showMessageBar) {
    setTimeout(() => {
      setShowMessageBar(false);
    }, 2000);
  }
  if (showSubStauts) {
    setTimeout(() => {
      setShowSubStatus(false);
    }, 2000);
  }
}, [showMessageBar,showSubStauts]);
useEffect(()=>{
  getDemandList()
  setHasMore(true)
  setFetchOptions({...fetchOptions,skip: 0,limit: 15})
},[isModalOpen,fetchOptions.sort_field,fetchOptions.sort_type,showMessageBar])
useEffect(() => {
setUpdateCallout(false)
getDemandList()
setHasMore(true)
setFetchOptions({...fetchOptions,skip: 0,limit: 15})
}, [showAssignDemandmodal,showSubStauts])
  const getDemandList = debounce(() => {
    setIsDataLoaded(false);
    if (userId) {
      axiosPrivateCall
        .get(`/api/v1/BDE/listUserLevelActiveDemands?user_id=${userId}&skip=0&limit=15&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
        .then((res) => {
        setDemandList(res.data.demands);
          setIsDataLoaded(true);
          setHasMore(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      axiosPrivateCall.get(`/api/v1/demand/listUserCreatedDemands`)
      .then((res) => {

        const reverseData=res.data.reverse();
        setDemandList(reverseData);
        setIsDataLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }
}, 500);

  const [DropdownSearch, setDropdownSearch] = useState('')

  const handleDropdownChange = (e, item) => {
    setDropdownSearch(item.key)
  }

  const searchDemandList = (e) => {
    const searchValue = e
    if (searchValue === '') {
      getDemandList();
      return
    }
    setIsDataLoaded(false)
    setIsUserSearching(true)
    setFetchOptions(prevData => {
      return {
        ...prevData,
        search_field: searchValue
      }
    })
    axiosPrivateCall.get(`/api/v1/demand/searchUserCreatedDemands?skip=0&limit=15&field_name=${DropdownSearch}&field_value=${searchValue}`)
      .then(res => {
        setDemandList(res.data);
        setIsDataLoaded(true)
      }).catch(e => {
        console.log(e)
      })
  }

  const clearSearchBox = () => {
    setIsUserSearching(false)
    setFetchOptions(prevData => {
      return {
        ...prevData,
        search_field: ''
      }
    })
  }

  const fetchMoreData = () => {
    if (isUserSearching) {
      axiosPrivateCall.get(`/api/v1/demand/searchUserCreatedDemands?skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&field_name=job_title&field_value=${fetchOptions.search_field}`)
        .then(res => {
          const moreDemands = res.data;
          setDemandList([...demandList, ...moreDemands])
          if (moreDemands.length < 15 || moreDemands.length === 0) {
            setHasMore(false)
          }
          setFetchOptions((prevState) => {

            return {

              ...prevState,
              skip: fetchOptions.skip + fetchOptions.limit,
            }

          })
        }).catch(e => {
          console.log(e)
        })
    } else if (userId) {
      axiosPrivateCall
        .get(`/api/v1/BDE/listUserLevelActiveDemands?user_id=${userId}&skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
        .then((res) => {
          const moreDemands = res.data.demands;
          setDemandList([...demandList, ...moreDemands])
          if (moreDemands.length < 15 || moreDemands.length === 0) {
            setHasMore(false)
          }

          setFetchOptions((prevState) => {

            return {

              ...prevState,
              skip: fetchOptions.skip + fetchOptions.limit,
            }
          })
        }).catch(e => {
          console.log(e)
        })
    }
    else {
      axiosPrivateCall.get(`/api/v1/demand/listUserLevelActiveDemands?skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
        .then(res => {
          const moreDemands = res.data;
          setDemandList([...demandList, ...moreDemands])
          if (moreDemands.length < 15 || moreDemands.length === 0) {
            setHasMore(false)
          }
        }).catch(e => {
          console.log(e)
        })
    }
  }

  const clickSortHandler = (key) => {

    if (!isDataLoaded) return;
    if (key === 'createdAt') {
      setFetchOptions(
        {
          ...fetchOptions,
          sort_field: key,
          sort_type: fetchOptions.sort_type === -1 ? 1 : -1,
        }
      )
    }
  }

  
  function handleAddSubmision(id) {
    setDemandId(id);
    setSubIsModalOpen(true);
  };

  const assignDemandModalHandler = (_id) => {
    setShowAssignDemandModal(true)
    setAssignDemandId(_id)

  }
  const handleUpdate = (showpop) => {
    const deleteObj = updateId
    if (!showpop) {
      setShowPopup(!showPopup)
      axiosPrivateCall.post(`/api/v1/demand/deleteDemand`, deleteObj).then(res => {
        setSubmitDel(!isSubmitDel)
        const demandArrList = demandList
        setDemandList(demandArrList.filter(demand => demand._id !== deleteObj._id));
      }).catch(e => {
        console.log(e)
        setUpdateCallout(false)
      })
    }
  }

  const addEllipsisToName = (name) => {
    if (name.length > 14) {
      let new_name = name.substring(0, 12).padEnd(15, '.')
      return new_name
    }
    else return name;
  };

  function skillLister(Arr) {
    let skills = '';
    if (Arr.length > 2) {
      Arr.map((data, index) => {
        if (index === 2) {
          skills = data.skill;
        }
      })
      return skills;
    } else {
      return "-";
    }
  }

const download=()=>{
  setLoading(true);
  setTimeout(() => {
    axiosPrivateCall
      .get(`/api/v1/demand/downloadMyDemands?&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        setCompleted(true);
        setTimeout(() => {
          setCompleted(false);
        }, 4000);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  }, 1000);
}


  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <DeletePopup showPopup={showPopup}
          setShowPopup={setShowPopup}
          handleUpdate={handleUpdate}
          deleteId={deleteId}
          updateCallout={updateCallout}
          setUpdateCallout={setUpdateCallout}
        />
        {isModalDemandOpen && 
        <ViewAndEditDemand demandId={demandId} isModalDemandOpen={isModalDemandOpen} setIsModalDemandOpen={setIsModalDemandOpen}/>}
        {isModalOpen && <AddDemandModal showMessageBar={showMessageBar} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}
          setShowMessageBar={setShowMessageBar} focus={false} />}
        {showAssignDemandmodal && <AssignDemandModal assignDemandId={assignDemandId} isModalOpen={showAssignDemandmodal} setIsModalOpen={setShowAssignDemandModal} />}
        {subIsModalOpen && 
        <AddSubmissionModal subIsModalOpen={subIsModalOpen} setSubIsModalOpen={setSubIsModalOpen} showSubStauts={showSubStauts} setShowSubStatus={setShowSubStatus} demandId={demandId} />}
        <div className={styles.nav_container}>
          <div className={styles.title}>My Demand</div>
          {showMessageBar && <div >
            <MessageBar onDismiss={() => setShowMessageBar(!showMessageBar)} styles={messageBarStyles} dismissButtonAriaLabel="Close" messageBarType={MessageBarType.success}>
              Demand added successfully
            </MessageBar>
          </div>}
          {isSubmitDel && <div >
            <MessageBar onDismiss={() => setSubmitDel(!isSubmitDel)} styles={messageBarStyles} dismissButtonAriaLabel="Close" messageBarType={MessageBarType.success}>
              Demand Deleted successfully
            </MessageBar>
          </div>}
          {showSubStauts && <div >
            <MessageBar onDismiss={() => setShowSubStatus(!showSubStauts)} styles={messageBarStyles} dismissButtonAriaLabel="Close" messageBarType={MessageBarType.success}>
              Submission added successfully
            </MessageBar>
          </div>}
          <div className={styles.search_dropdown}>
                         <Dropdown placeholder='select search field' onChange={handleDropdownChange}  options={options} styles={dropdownStyles}/>
                        </div> 
          <div className={styles.nav_items}>
   
            <SearchBox onSearch={(e)=>searchDemandList(e)} disabled={DropdownSearch == ""? true:false}  onClear={clearSearchBox} placeholder=" " iconProps={searchIcon} className={styles.search}  />
            <PrimaryButton style={{ display: (demandCreator == "true") ? 'block' : 'none' }} onClick={(e) => { setTimeout(() => setIsModalOpen(!isModalOpen), 0); }} text="Add" iconProps={addIcon} />
            {loading ? (<Spinner size={SpinnerSize.medium} className={iconClass} />) :
              completed ?(<FontIcon iconName="CheckMark" className={iconClass} />) :
              (<FontIcon iconName="Download" onClick={download} className={iconClass} />)}
          </div>
        </div>
        <div id="scrollableDiv" className={styles.table_container}>
          <InfiniteScroll style={{ overflow: 'visible', height: '100%' }} dataLength={demandList.length} loader={isDataLoaded && demandList.length >= 15 && <h4>Loading...</h4>}
            hasMore={hasMore} next={fetchMoreData} scrollableTarget="scrollableDiv">
            <table>
              <thead className={styles.table_header}>
                <tr className={styles.table_row}>
                  {columns.map((column) =>
                    <th onClick={() => clickSortHandler(column.columnKey)} className={styles.table_headerContents} key={column.columnKey}>
                      <div className={styles.table_heading}>
                        <div>{column.label}</div>
                        {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                      </div>
                    </th>)}
                </tr>
              </thead>
              <tbody>
              {isDataLoaded && demandList.length === 0 ? (
                              <tr>
                                <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                                  <img src={NoDataFound} alt="NoDataimage" width={"190px"} height={"200px"} />
                                </td>
                            </tr>
                           ) : (
                            <>
                {isDataLoaded && demandList.map((demand, demand_index) =>
                  <tr className={styles.table_row} key={demand._id}>
                      <td onClick={() => navigateTo(`/demand/viewandeditdemand?demand_id=${demand._id}`, { state: { demandData:demand, demandListing: 'demandListing' } })} className={styles.table_dataContents}><span className={styles.custom_link}>{demand.DemandId}</span></td>
                      <td className={styles.table_dataContents}
                      onMouseEnter={() => setHoverCallout(demand.created_by)}
                      onMouseLeave={() => setHoverCallout('')}
                      id={`${demand.created_by?.first_name}_${demand._id}`.replaceAll(" ", "_")}>
                      {addEllipsisToName(demand.job_title)}
                      {(demand.job_title).length >= 14 && hoverCallout === demand.created_by && <Callout alignTargetEdge={true}
                        isBeakVisible={false} styles={CalloutNameStyles}
                        directionalHint={DirectionalHint.bottomLeftEdge} target={`#${demand.created_by?.first_name}_${demand._id}`.replaceAll(" ", "_")}>
                        {demand.job_title}
                      </Callout>
                      }
                    </td>
                    <td className={styles.table_dataContents}>{ISOdateToCustomDate(demand.createdAt)}</td>
                    
                    <td className={styles.table_dataContents}
                      onMouseEnter={() => setHoverCallout(demand.client)}
                      onMouseLeave={() => setHoverCallout('')}
                      id={`${demand.client}_${demand._id}`.replaceAll(" ", "_")}>
                      {addEllipsisToName(demand.client)}
                      {(demand.client).length >= 14 && hoverCallout === demand.client && <Callout alignTargetEdge={true}
                        isBeakVisible={false} styles={CalloutNameStyles}
                        directionalHint={DirectionalHint.bottomLeftEdge} target={`#${demand.client}_${demand._id}`.replaceAll(" ", "_")}>
                        {demand.client}
                      </Callout>
                      }
                    </td>
                    <td className={styles.table_dataContents}>{ISOdateToCustomDate(demand.due_date)}</td>
                    <td className={styles.table_dataContents}>{`${Math.floor(demand.minimum_experience / 12)} years ${demand.minimum_experience % 12} months`}</td>
                    <td className={styles.table_dataContents}
                      onMouseEnter={() => setHoverCallout(demand._id)}
                      onMouseLeave={() => setHoverCallout("")}
                      id={`primary_skill_${demand._id}`}>
                      {addEllipsisToName(`${demand.skillset[0]?.skill ? demand.skillset[0]?.skill : "-"}`)}
                      {demand.skillset[0]?.skill?.length >= 14 && hoverCallout === demand._id && <Callout alignTargetEdge={true}
                        isBeakVisible={false} styles={CalloutNameStyles}
                        directionalHint={DirectionalHint.bottomLeftEdge} target={`#primary_skill_${demand._id}`}>
                        {demand.skillset[0]?.skill}
                      </Callout>
                      }
                    </td>
                
                    <td className={styles.table_dataContents}>{demand.created_by?.first_name + " " + demand.created_by?.last_name}</td>
                    <td className={styles.table_dataContents1}>{demand.submission_count}</td>
                     <td className={styles.table_dataContents}>{demand.status}</td>
                    <td className={styles.table_dataContents}>
                      <div id={`Dp${demand._id}`} onClick={() => { setRowId(demand._id); setUpdateCallout(true) }} className={styles.moreOptions}>
                        <FontIcon iconName='MoreVertical' className={iconClass1} />
                        {rowId === demand._id &&
                          updateCallout && <Callout gapSpace={0} target={`#Dp${demand._id}`} onDismiss={() => setRowId('')}
                            isBeakVisible={false} directionalHint={DirectionalHint.bottomCenter}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <DefaultButton onClick={() => navigateTo(`/demand/viewandeditdemand?demand_id=${demand._id}`, { state: { demandData:demand, demandListing: 'demandListing' } })} text="Edit Demand" styles={calloutBtnStyles} />
                              <DefaultButton onClick={() => { handleAddSubmision(demand._id) }} text="Add Submission" styles={calloutBtnStyles} />
                              <DefaultButton text='View Submissions' onClick={() => navigateTo(`/submission/managesubmissions?demand_id=${demand._id}`)} styles={calloutBtnStyles} />
                              <DefaultButton onClick={() => assignDemandModalHandler(demand._id)} text={"Assign Demand"} styles={calloutBtnStyles} />
                            </div>
                          </Callout>
                        }
                      </div>
                    </td>
                  </tr>)}
                {!isDataLoaded && items.map(employee =>
                  <tr className={styles.table_row} >
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}><Shimmer /></td>
                    <td className={styles.table_dataContents}>
                      <div className={styles.moreOptions} >
                        <FontIcon iconName='MoreVertical' className={iconClass1} />
                      </div>
                    </td>
                  </tr>)}
                  </>
                   )} 
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );

};

export default DemandListing;








import React, { useEffect, useState, useId } from "react";
import styles from "./ManageEmployee.module.css"
import { PrimaryButton, SearchBox, FontIcon, mergeStyles, mergeStyleSets, Dropdown } from '@fluentui/react';
import AddClientModal from "./AddClientModal";
import { DefaultButton, Callout, DirectionalHint } from '@fluentui/react';
import { Shimmer } from '@fluentui/react';
import { useNavigate, useSearchParams } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { axiosPrivateCall } from "../constants";
import Nomatchimg from "../assets/no.png"
import { debounce } from 'lodash';
import { Spinner, SpinnerSize } from "@fluentui/react";
import { ISOdateToCustomDate } from "../utils/helpers";

const addIcon = { iconName: 'Add' };
const searchIcon = { iconName: 'Search' };

const dropdownStyles = mergeStyles({
  dropdown: { width: '200' }
});

const iconClass = mergeStyles({
  fontSize: 20,
  height: 20,
  width: 20,
  margin: '0 10px',
  color: '#999DA0',
  cursor: 'pointer'
});

const iconClass1 = mergeStyles({
  fontSize: 12,
  height: 12,
  width: 12,
  margin: '0 ',
  color: '#999DA0',
  cursor: 'pointer'
});

const iconClassToast = mergeStyles({
  fontSize: 24,
  height: 24,
  width: 24,
  color: '#107C10',
});

const searchFieldStyles = mergeStyleSets({
  root: { width: '185px' }
});

const calloutBtnStyles = {
  root: {
    border: 'none',
    padding: '0px 10px',
    textAlign: 'left',
    height: '20px'

  }
}

const CalloutNameStyles = {
  calloutMain: {
    background: '#EDF2F6',
    padding: '2',

  },
}

const dropdownOptions = [
  { key: 'ClientId', text: 'Client ID' },
  { key: 'company_name', text: 'Company Name' },
  { key: 'primary_email', text: 'Email' },
  { key: 'primary_mobile', text: 'Mobile' }
];

let items = Array(4).fill(null);

function ClientListing(props) {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [isSubmitDel, setSubmitDel] = useState(false);
  const [isSubmitUpdate, setSubmitUpdate] = useState(false);
  const [clientList, setClientList] = useState('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [rowId, setRowId] = useState('');
  const [hoverCallout, setHoverCallout] = useState('');
  const [updateCallout, setUpdateCallout] = useState(false);
  const [isUserSearching, setIsUserSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [dropdownSearch, setDropdownSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [fetchOptions, setFetchOptions] = useState({
    skip: 0,
    limit: 15,
    sort_field: 'createdAt',
    sort_type: -1,
    search_field: ''
  })

  const [hasMore, setHasMore] = useState(true)
  const [searchParams, setSearchParams] = useSearchParams();

  const userId = searchParams.get('user_id')
  const type = searchParams.get('type')

  const navigateTo = useNavigate();

  const columns = [
    {
      columnKey: 'client_id',
      label: 'Client ID',
    }, {
      columnKey: 'company_name',
      label: 'Company Name',
      icon: `${fetchOptions.sort_field === 'company_name' && fetchOptions.sort_type === 1 ? 'SortUp' : 'SortDown'}`
    }, {
      columnKey: 'point_of_contact',
      label: 'Point Of Contact',
    }, {
      columnKey: 'designation',
      label: 'Designation',
    },  {
      columnKey: 'email',
      label: 'Email ID'
    }, {
      columnKey: 'mobile',
      label: 'Mobile',
    }, {
      columnKey: 'empanalment',
      label: 'Empanalment',
    }, {
      columnKey: 'expansion',
      label: 'Expansion',
    }, {
      columnKey: 'city',
      label: 'City',
    }, {
      columnKey: 'created_by',
      label: 'Created by',
    },
   {
      columnKey: 'createdAt',
      icon: `${fetchOptions.sort_field === 'createdAt' && fetchOptions.sort_type === 1 ? 'SortUp' : 'SortDown'}`,
      label: 'Created on',
    }, {
      columnKey: 'More Options',
      label: ' '
    }];

    const viewFileInNewTab = (fileUrl) => {
      const newWindow = window.open('', '_blank');
      newWindow.document.write('<html><head><title>File Preview</title></head><body>');
      if (fileUrl.endsWith('.pdf')) {
        newWindow.document.write(`<iframe src="${fileUrl}" style="width: 80%; height: 700px;"></iframe>`);
      } else if (fileUrl.endsWith('.xlsx')) {
        newWindow.document.write(`<iframe src="https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}" style="width: 80%; height: 700px;"></iframe>`);
      } else if (fileUrl.endsWith('.pptx')) {
        newWindow.document.write(`<iframe src="https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}" style="width: 80%; height: 700px;"></iframe>`);
      } else {
        console.error('Unsupported file type');
      }
      newWindow.document.write('</body></html>');
      newWindow.document.close();
    };  

  useEffect(() => {
    getClientData();
    setHasMore(true)
    setFetchOptions({ ...fetchOptions, skip: 0, limit: 15 })

  }, [isModalOpen, fetchOptions.sort_field, fetchOptions.sort_type])

  const handleSearchInputChange = (event) => {
    if (!event || !event.target) {
      setSearchTerm('');
      return;
    }
    const value = event.target.value;

    switch (dropdownSearch) {
      case 'company_name':
        if (value && !/^[a-zA-Z\s]+$/.test(value)) {
          return;
        }
        break;
      case 'ClientId':
        if (value && !/^[0-9a-zA-Z]+$/.test(value)) {
          return;
        }
        break;
      case 'primary_email':
        // if (value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        //   return;
        // }
        break;
      case 'primary_mobile':
        if (value && !/^[0-9]+$/.test(value)) {
          return;
        }
        break;
      default:
        break;
    }
    setSearchTerm(value);
  };

  const handleDropdownChange = (event, option) => {
    setDropdownSearch(option.key);
    setSearchTerm('');
  };

  useEffect(() => {
    if (isSubmitSuccess || isSubmitDel || isSubmitUpdate) {

      const timeout = setTimeout(() => {
        if (isSubmitSuccess) setSubmitSuccess(false);
        if (isSubmitDel) setSubmitDel(false);
        if (isSubmitUpdate) setSubmitUpdate(false);

      }, 2000);
  
      return () => clearTimeout(timeout);
    }
  }, [isSubmitSuccess, isSubmitDel, isSubmitUpdate]);

  const getClientData = debounce(() => {
    setIsDataLoaded(false);
    if (type === "expansion" && userId) {
      axiosPrivateCall
        .get(`/api/v1/BDE/listUserLevelExpansion?user_id=${userId}`)
        .then((res) => {
          const moreClients = res.data;
          setClientList(moreClients);
          setIsDataLoaded(true);
        })
        .catch((e) => {
          console.log(e);
          setIsDataLoaded(true);
        });
    } else if (type === "empanelment" && userId) {
      axiosPrivateCall
        .get(`/api/v1/BDE/listUserLevelEmpanelment?user_id=${userId}`)
        .then((res) => {
          setClientList(res.data);
          setIsDataLoaded(true);
        })
        .catch((e) => {
          console.log(e);
          setIsDataLoaded(true);
        });
    } else {
      axiosPrivateCall
        .get(
          `/api/v1/client/listClients?skip=0&limit=15&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`
        )
        .then((res) => {
          console.log(res.data);
          console.log("hi");
          setClientList(res.data);
          setIsDataLoaded(true);
        })
        .catch((e) => {
          console.log(e);
          setIsDataLoaded(true); // Set the flag to true even on error
        });
    }
  }
    , 500);

  const searchClientList = (e) => {
    const searchValue = e;

    if (searchValue === '') {
      getClientData();
      return;
    }

    setIsDataLoaded(false);
    setIsUserSearching(true);

    setFetchOptions((prevData) => {
      return {
        ...prevData,
        search_field: searchValue,
      };
    });

    axiosPrivateCall
      .get(`/api/v1/client/searchClients?field_name=${dropdownSearch}&field_value=${searchValue}`)
      .then((res) => {
        console.log(res);
        console.log(res.data);
        setClientList(res.data);
        setIsDataLoaded(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const clearSearchBox = () => {
    setIsUserSearching(false)

    setFetchOptions(prevData => {
      return {
        ...prevData,
        search_field: ''
      }
    })
  }

  const fetchMoreData = () => {
    if (isUserSearching) {
      axiosPrivateCall.get(`/api/v1/client/searchClient?skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&field_name=email&field_value=${fetchOptions.search_field}`)
        .then(res => {
          const moreClients = res.data;
          setClientList([...clientList, ...moreClients])
          if (moreClients?.length < 15 || moreClients?.length === 0) {
            setHasMore(false)
          }
          setFetchOptions((prevState) => {
            return {
              ...prevState,
              skip: fetchOptions.skip + fetchOptions.limit,
            }

          })
        }).catch(e => {
          console.log(e)
        })
    } else if (type === "expansion" && userId) {
      axiosPrivateCall
        .get(`/api/v1/BDE/listUserLevelExpansion?user_id=${userId}&skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
        .then((res) => {
          const moreClients = res.data;
          console.log(moreClients?.length)
          console.log("hyy");
          setClientList([...clientList, ...moreClients])
          if (moreClients.length < 15 || moreClients?.length === 0) {
            setHasMore(false)
          }
          setFetchOptions((prevState) => {
            return {
              ...prevState,
              skip: fetchOptions.skip + fetchOptions.limit,
            }

          })
        }).catch(e => {
          console.log(e)
        })

    }
    else if (type === "empanelment" && userId) {
      axiosPrivateCall
        .get(`/api/v1/BDE/listUserLevelEmpanelment?user_id=${userId}&skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
        .then((res) => {
          const moreClients = res.data;
          console.log(moreClients?.length)

          setClientList([...clientList, ...moreClients])
          if (moreClients?.length < 15 || moreClients?.length === 0) {
            setHasMore(false)
          }
          setFetchOptions((prevState) => {
            return {
              ...prevState,
              skip: fetchOptions.skip + fetchOptions.limit,
            }

          })
        }).catch(e => {
          console.log(e)
                })
    }

    else {

      axiosPrivateCall.get(`/api/v1/client/listClients?skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
        .then(res => {
          const moreClients = res.data;
          console.log(moreClients?.length)
          console.log("hy");
          setClientList([...clientList, ...moreClients])
          if (moreClients?.length < 15 || moreClients?.length === 0) {
            setHasMore(false)
          }

          setFetchOptions((prevState) => {

            return {

              ...prevState,
              skip: fetchOptions.skip + fetchOptions.limit,
            }

          })
        }).catch(e => {
          console.log(e)
        })
    }
    console.log('getting more data')
  }

  const clickSortHandler = (key) => {

    console.log(key)

    if (!isDataLoaded) return;

    if (key === 'company_name') {
      setFetchOptions(
        {
          ...fetchOptions,
          sort_field: key,
          sort_type: fetchOptions.sort_type === -1 ? 1 : -1,
        }
      )
    }

    if (key === 'createdAt') {
      setFetchOptions(
        {
          ...fetchOptions,
          sort_field: key,
          sort_type: fetchOptions.sort_type === -1 ? 1 : -1,
        }
      )
    }
  }
  const addEllipsisToName = (name) => {

    if (name?.length > 13) {
      let new_name = name.substring(0, 13).padEnd(16, '.')
      return new_name
    }
    else return name

  }


  const editClient1 = (_id, name, value, index) => {
    const updateObj = {}

    if (name === 'status') {
      updateObj['_id'] = _id

      if (value === false) {
        updateObj[name] = true
      }

      console.log(updateObj, _id)
      axiosPrivateCall.post('/masterlist/editclient?client_id=${client._id}', updateObj).then(res => {
        const clientArrList = clientList;
        clientArrList[index][name] = updateObj[name]
        setSubmitUpdate(false)
        setClientList(clientArrList)
        setUpdateCallout(false)

      }).catch(e => {
        console.log(e)
        setUpdateCallout(false)
      })
    }
  }

  const deleteClient = (_id) => {
    const deleteObj = { "_id": _id }
    axiosPrivateCall.post('/api/v1/client/deleteClient', deleteObj).then(res => {
      setSubmitDel(!isSubmitDel);
      const clientArrList = clientList;
      setClientList(clientArrList.filter(client => client._id !== _id))

    }).catch(e => {
      console.log(e)
      setUpdateCallout(false)
    })

  }

  const editClient = (clientData) => {
    axiosPrivateCall.post('/api/v1/client/editClient', clientData)
      .then(res => {
        setSubmitUpdate(true);  // Trigger toast for success
        const updatedClientList = clientList.map(client => 
          client._id === clientData._id ? { ...client, ...clientData } : client
        );
        setClientList(updatedClientList);
      })
      .catch(e => {
        console.log(e);
        setUpdateCallout(false);
      });
  };

  const downloadClients = () => {
    setLoading(true);
    setTimeout(() => {
      axiosPrivateCall
        .get(`/api/v1/client/downloadClient?skip=0&limit=15&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`, {
          responseType: 'blob',
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${Date.now()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          setCompleted(true);
          setTimeout(() => {
            setCompleted(false);
          }, 4000);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    }, 1000);
}

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        {isModalOpen && <AddClientModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isSubmitSuccess={isSubmitSuccess}
          setSubmitSuccess={setSubmitSuccess} />}

        <div className={styles.nav_container}>
          <div className={styles.title}>Manage Clients</div>

          {isSubmitSuccess && (<div className={styles.toast}>
            <div className={styles.toast_title}>
              <FontIcon iconName="StatusCircleCheckmark" className={iconClassToast} />
              <div>Client Added Successfully!</div>
            </div>

            <FontIcon iconName="StatusCircleErrorX" className={iconClass} onClick={() => setSubmitSuccess(false)} />
          </div>)
          }

          {isSubmitDel && (<div className={styles.toast}>
                      <div className={styles.toast_title}>
                        <FontIcon iconName="StatusCircleCheckmark" className={iconClassToast} />
                        <div>Client Deleted Successfully</div>
                      </div>

                      <FontIcon iconName="StatusCircleErrorX" className={iconClass} onClick={() => setSubmitDel(false)} />
                    </div>)
                    }

                    {isSubmitUpdate && (<div className={styles.toast}>
                                          <div className={styles.toast_title}>
                                            <FontIcon iconName="StatusCircleCheckmark" className={iconClassToast} />
                                            <div>Client Updated Successfully!</div>
                                          </div>

                                          <FontIcon iconName="StatusCircleErrorX" className={iconClass} onClick={() => setSubmitUpdate(false)} />
                                        </div>)
                                        }

          <div className={styles.nav_items}>
            <Dropdown
              placeholder="Select Search Field"
              options={dropdownOptions}
              styles={dropdownStyles}
              onChange={handleDropdownChange}
              selectedKey={dropdownSearch}
              className={styles.customDropdown}
            />

            <SearchBox
              styles={searchFieldStyles}
              onChange={handleSearchInputChange}
              onSearch={(e) => searchClientList(e)}
              onClear={clearSearchBox}
              disabled={dropdownSearch === ""}
              placeholder=" "
              iconProps={searchIcon}
              className={styles.search}
              value={searchTerm}
              showIcon
              maxLength={dropdownSearch === 'primary_mobile' ? 10 : undefined}
            />

            {/* <FontIcon iconName="Breadcrumb" className={iconClass} /> */}
            <PrimaryButton text="Add" iconProps={addIcon}
              onClick={() => { setIsModalOpen(!isModalOpen); setSubmitSuccess(false); }} />
             {loading ? (<Spinner size={SpinnerSize.medium} className={iconClass} />) :
              completed ?(<FontIcon iconName="CheckMark" className={iconClass} />) :
              (<FontIcon iconName="Download" onClick={downloadClients} className={iconClass} />)}
          </div>
        </div>

        <div id="scrollableDiv" className={styles.table_container}>
          <InfiniteScroll style={{ overflow: 'visible', height: '100%' }} dataLength={clientList?.length} loader={isDataLoaded && clientList?.length >= 15 && <h4>Loading...</h4>}
            hasMore={hasMore} next={fetchMoreData} scrollableTarget="scrollableDiv">
            <table>
              <thead className={styles.table_header}>
                <tr className={styles.table_row}>
                  {columns?.map((column) =>
                    <th onClick={() => clickSortHandler(column.columnKey)} className={styles.table_headerContents} key={column.columnKey}>
                      <div
                        className={styles.table_heading}>
                        <div>{column.label}</div>
                        {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                      </div>
                    </th>)}
                </tr>
              </thead>

              <tbody>
                {isDataLoaded && clientList?.length === 0 ? (
                  <tr>
                    <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                      <img src={Nomatchimg} alt="image" width={"180px"} height={"200px"} />
                    </td>
                  </tr>
                ) : (
                  <>
                    {isDataLoaded && clientList?.map((client, client_index) => (
                      <tr className={client.status ? `${styles.table_row}` : `${styles.table_row_idle}`} key={client._id}>
                        <td onClick={() => navigateTo(`/masterlist/viewclient?client_id=${client._id}`)}
                          className={styles.table_dataContents}>
                            <span className={styles.custom_link}>{client.ClientId}</span>
                          
                        </td>
                        <td onMouseEnter={() => setHoverCallout(client._id)} onMouseLeave={() => setHoverCallout('')}
                          id={`ME${client._id}`}
                          className={`${styles.table_dataContents} `}>

                          {addEllipsisToName(`${client.company_name} `)}

                          {(client.company_name)?.length >= 13 && hoverCallout === client._id &&
                            <Callout alignTargetEdge={true}
                              bounds={e => { console.log('log', e) }}
                              isBeakVisible={false} styles={CalloutNameStyles}
                              directionalHint={DirectionalHint.bottomLeftEdge}
                              target={`#ME${client._id}`}>

                              {`${client.company_name}`}
                            </Callout>
                          }
                        </td>

                        <td className={styles.table_dataContents}>{client?.point_of_contact_name ? client.point_of_contact_name : "-"}</td>
{/* <td className={styles.table_dataContents}>{client?.website ? client.website : "-"}</td> */}
<td className={styles.table_dataContents}>{client?.destination ? client.destination : "-"}</td>
<td className={styles.table_dataContents}>{client?.primary_email ? client.primary_email : "-"}</td>
<td className={styles.table_dataContents}>{client?.primary_mobile ? client.primary_mobile : "-"}</td>
<td className={styles.table_dataContents}>{client?.empanelment ? client.empanelment : "-"}</td>
<td className={styles.table_dataContents}>{client?.expansion ? client.expansion : "-"}</td>
<td className={styles.table_dataContents}>{client?.city}</td>
<td className={styles.table_dataContents}>{Array.isArray(client.created_by) ? client.created_by.map((user, index) => (
                          <span key={index}>{`${user.first_name} ${user.last_name}`} {index !== client.created_by?.length - 1 && ', '}</span>
                        )) : `${client.created_by?.first_name} ${client.created_by?.last_name}`}
                        </td>
                       
                         {/* <td className={styles.table_dataContents}>  
                        {client?.template_url?.length > 0 ? (
                        <div>
                        <a
                          href="javascript:void(0);"
                          onClick={() => viewFileInNewTab(client?.template_url[0])}
                        >
                        Link
                        </a>
                        </div>
                        ) : (
                          '-'
                        )}</td> */}

                        <td className={styles.table_dataContents}>{ISOdateToCustomDate(client.createdAt)}</td>

                        <td className={styles.table_dataContents}>
                          <div id={`${client.pan_number}${client._id}`}
                            onClick={() => { setRowId(client._id); setUpdateCallout(true) }}
                            className={styles.moreOptions}>
                            <FontIcon iconName='MoreVertical' className={iconClass1} />
                            {rowId === client._id && updateCallout && <Callout gapSpace={0}
                              target={`#${client.pan_number}${client._id}`} onDismiss={() => setRowId('')}
                              isBeakVisible={false}
                              directionalHint={DirectionalHint.bottomCenter}>

                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <DefaultButton
                                text="Edit"
                                onClick={() => {
                                  setSubmitUpdate(false);
                                  navigateTo(`/masterlist/editclient?client_id=${client._id}`);
                                }}
                                styles={calloutBtnStyles}
                              />

                                {/* <DefaultButton onClick={() => editClient(client._id)} text='Edit' styles={calloutBtnStyles} /> */}
                               {/*  <DefaultButton onClick={() => { }} text='Reject' styles={calloutBtnStyles} /> */}
                                <DefaultButton onClick={() => deleteClient(client._id)} text="Delete" styles={calloutBtnStyles} />
                              </div>
                            </Callout>
                            }
                          </div>
                        </td>
                      </tr>
                    ))}
                    {!isDataLoaded && items?.map(client => (
                      <tr className={styles.table_row}>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}><Shimmer /></td>
                        <td className={styles.table_dataContents}>
                          <div className={styles.moreOptions}>
                            <FontIcon iconName='MoreVertical' className={iconClass1} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>

            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );

};

export default ClientListing;
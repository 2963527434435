import React, { useState, useEffect, useRef } from "react";
import { Label, Modal } from "@fluentui/react";
import styles from "./AddOpportunityModal.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { TextField, PrimaryButton, DefaultButton, DatePicker } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { Popup } from "../components/Popup";
import { isEmpty, isNumOnly } from "../utils/validation";
import { axiosPrivateCall } from "../constants";
import { UploadPopup } from "../components/UploadModal";
import uploadImage from "../assets/upload_cloud_image.png";
import ComboBox from "../components/ComboBox/ComboBox";
import Popup1 from "../components/PopupCRM";

const buttonStyles = {
  root: {
    marginTop: "3px",
    height: "30px",
    borderRadius: "2px",
  },
};

const saveButtonStyle = {
  root: {
    marginTop: "22px",
    height: "30px",
    borderRadius: "2px",
  },
};

const contractIconClass = mergeStyles({
  fontSize: 20,
  height: "20px",
  width: "20px",
  cursor: "pointer",
});

const closeIconClass = mergeStyles({
  fontSize: 16,
  height: "20px",
  width: "20px",
  cursor: "pointer",
});

const tableCloseIconClass = mergeStyles({
  fontSize: 8,
  width: "12px",
  cursor: "pointer",
  color: "red",
  marginLeft: "0px",
  marginTop: "5px",
  paddingLeft: "1px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const getDropdownStyles = (width, marginTop, maxWidth, props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    title: {
      height: "29px",
      width: width,
      maxWidth: maxWidth,
      display: "flex",
      alignItems: "center",
      color: titleColor,
    },
    dropdown: {
      marginTop: marginTop,
      borderRadius: borderRadius,
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: borderRadius,
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: borderRadius,
        },
      },
    },
  };
};

const calendarClass = (value) => {
  return mergeStyleSets({
    root: {
      "*": {
        fontSize: 13,
        height: "29px !important",
        lineHeight: "20px !important",
        borderColor: "#E1E5E8 !important",
        borderRadius: "4px",
        lineHeight: "26px !important",
        color: value.length === 0 ? "LightGray !important" : "black !important",
      },
    },
    field: {
      color: value.length === 0 ? "LightGray !important" : "black !important",
      fontSize: 12,
      input: {
        color: value.length === 0 ? "LightGray !important" : "black !important",
        "&::placeholder": {
          color: value.length === 0 ? "LightGray !important" : "black !important",
        },
      },
    },
    icon: {
      height: "14px !important",
      width: "8px !important",
      left: "80%",
      padding: "0px 0px",
      scale: "90%",
    },
    statusMessage: { marginBottom: "-25px" },
  });
};

const getTextFieldStyles = (width, marginTop, maxWidth, props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#D7D7D7";
  }
  return {
    fieldGroup: {
      width: width,
      marginTop: marginTop,
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: "4px",
      boxSizing: "border-box",
      selectors: {
        ":focus": { borderColor: borderColor },
        ":hover": { borderColor: borderColor },
      },
    },
    field: {
      color: "#5B5F62",
      input: {
        color: "red",
        "&::placeholder": {
          color: titleColor,
          fontSize: 12,
        },
      },
    },
    ...(maxWidth && { maxWidth: maxWidth }),
  };
};

const regionLocationOption = [
  { key: "Asia-Pacific (APAC)", text: "Asia-Pacific (APAC)" },
  { key: "Africa (MENA)", text: "Africa (MENA)" },
  { key: "Central Asia, Eurasia", text: "Central Asia, Eurasia" },
  { key: "East Asia", text: "East Asia" },
  { key: "India", text: "India" },
  { key: "Latin America & the Caribbean", text: "Latin America & the Caribbean" },
  { key: "Middle East & North", text: "Middle East & North" },
  { key: "North America", text: "North America" },
  { key: "SubSaharan Africa", text: "SubSaharan Africa" },
  { key: "Southeast Asia", text: "Southeast Asia" },
];

const LevelOption = [
  { key: "Closure", text: "Closure" },
  { key: "Lead", text: "Lead" },
  { key: "Negotiate", text: "Negotiate" },
  { key: "Prospect", text: "Prospect" },
  { key: "Presales", text: "Presales" },
  { key: "Sales", text: "Sales" },
];

const statusOption = [
  { key: "Drop", text: "Drop" },
  { key: "Hold", text: "Hold" },
  { key: "In Progress", text: "In Progress" },
  { key: "Lost", text: "Lost" },
  { key: "N/A", text: "N/A" },
  { key: "Won", text: "Won" },
];

const requesttypeOption = [
  { key: "Client Request", text: "Client Request" },
  { key: "Contract", text: "Contract" },
  { key: "Presentation", text: "Presentation" },
  { key: "RFP", text: "RFP" },
  { key: "POC", text: "POC" },
];

const SSBuisnessUnitOption = [
  { key: "Consulting & Products", text: "Consulting & Products" },
  { key: "Data", text: "Data" },
  { key: "Digital", text: "Digital" },
  { key: "India Staffing", text: "India Staffing" },
  { key: "Training", text: "Training" },
  { key: "US Staffing", text: "US Staffing" },
  { key: "UAE Staffing", text: "UAE Staffing" },
];

const probOfWinning = [
  { key: "10%", text: "10%" },
  { key: "20%", text: "20%" },
  { key: "30%", text: "30%" },
  { key: "40%", text: "40%" },
  { key: "50%", text: "50%" },
  { key: "60%", text: "60%" },
  { key: "70%", text: "70%" },
  { key: "80%", text: "80%" },
  { key: "90%", text: "90%" },
  { key: "100%", text: "100%" },
];

const budgetStatus = [
  { key: "Approved", text: "Approved" },
  { key: "In Progress", text: "In Progress" },
  { key: "Not Started", text: "Not Started" },
  { key: "Reviewed", text: "Reviewed" },
  { key: "Submitted", text: "Submitted" },
];

const priorityLevel = [
  { key: "High", text: "High" },
  { key: "Low", text: "Low" },
  { key: "Medium", text: "Medium" },
];

const opputunitySourceOption = [
  { key: "Direct Inquiry", text: "Direct Inquiry" },
  { key: "Marketing", text: "Marketing" },
  { key: "Referral", text: "Referral" },
];

const AddOpportunity = (props) => {
  const { isModalOpen, setIsModalOpen, showMessageBar, setShowMessageBar } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [isModalShrunk, setIsModalShrunk] = useState(false);
  const [popupNotes, setPopupNotes] = useState("");
  const [currentHover, setCurrentHover] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const fileInputRef = useRef(null);
  const [DealName, getDealName] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [adminSales, setAdminSales] = useState([]);
  const [showPopup1, setShowPopup1] = useState(false);
  const [basicInfoerrors, setBasicInfoErrors] = useState({});
  const [pipelineData, setPipelineData] = useState({
    name: "",
    request_type: "",
    request_client: "",
    level: "",
    status: "",
    lead_source: "",
    tech_lead: "",
    customer_name: "",
    email_id: "",
    mobile_number: "",
    start_date: "",
    close_date: "",
    status: "",
    notes: "",
    probability_winning: "",
    priority_level: "",
    opportunity_source: "",
    assign_to: null,
    region_location: "",
    ss_businness_unit: "",
    currency: "",
    opportunity_value: "",
    budget_status: "",
    documents: [],
  });

  useEffect(() => {
    if (!showPopup1) {
      if (popupNotes) {
        setPipelineData((prevState) => ({
          ...prevState,
          notes: popupNotes,
        }));
      } else {
        setPipelineData((prevState) => ({
          ...prevState,
          status: "",
        }));
      }
    }
  }, [showPopup1, popupNotes]);

  const dateHandler = (date, name) => {
    setPipelineData((prevData) => {
      return {
        ...prevData,
        [name]: date,
      };
    });
    setCurrentHover("");
    setValidationErrors((prevErrors) => {
      return {
        ...prevErrors,
        [name]: "",
      };
    });
  };

  const getDealNameDetails = async () => {
    return axiosPrivateCall
      .get("api/v1/crm/getDealname")
      .then((response) => {
        getDealName(response.data);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  const validateFields = () => {
    const errors = {};

    if (!pipelineData.name) {
      errors.name = "required";
    }

    if (!pipelineData.request_type) {
      errors.request_type = "required";
    }

    if (!pipelineData.request_client) {
      errors.request_client = "required";
    }

    if (!pipelineData.level) {
      errors.level = "required";
    }

    if (!pipelineData.status) {
      errors.status = "required";
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    const fetchAdminSales = async () => {
      try {
        const response = await axiosPrivateCall.get("api/v1/crm/getadminsales");
        const adminSalesData = response.data;
        setAdminSales(adminSalesData);
      } catch (error) {
        console.error("Error fetching admin sales:", error);
      }
    };
  
    const fetchCurrency = async () => {
      try {
        const response = await axiosPrivateCall.get("https://countriesnow.space/api/v0.1/countries/currency");
        const currencyData = response.data.data;
        const currencyOptions = currencyData.map((currency) => ({
          value: currency.currency,
          label: currency.currency,
        }));
        const uniqueCurrencyOptions = currencyOptions.filter((currency, index, array) => {
          return array
            .slice(0, index)
            .every((prevCurrency) => prevCurrency.value !== currency.value && prevCurrency.label !== currency.label);
        });
        const uniqueCurrencyOptionsWithKeyAndText = uniqueCurrencyOptions.map((currency) => ({
          key: currency.value,
          text: currency.label,
        }));
        setCurrency(uniqueCurrencyOptionsWithKeyAndText);
      } catch (error) {
        console.error(error);
      }
    };
  
    const fetchDealNameDetails = async () => {
      try {
        await getDealNameDetails();
      } catch (error) {
        console.error("Error fetching deal name details:", error);
      }
    };
  
    // Call all the functions
    fetchAdminSales();
    fetchCurrency();
    fetchDealNameDetails();
  }, []);
  
  const submitHandler = async () => {
    const isValid = validateFields();
    if (isValid) {
      try {
        axiosPrivateCall
          .post("/api/v1/crm/addnew", pipelineData)
          .then((response) => {
            setPipelineData(response.data);
            setIsModalOpen(!isModalOpen);
            setShowMessageBar(!showMessageBar);
          })
          .catch((error) => {
            console.error("Error submitting data:", error);
            if (error.response) {
              const errors = {};
              errors.client_id = error.response.data;
              setValidationErrors(errors);
            }
          });
      } catch (error) {
        console.error("Error submitting data:", error);
      }
    }
  };

  const inputChangeHandler = (e, inputName) => {
    e.preventDefault();
    const { value } = e.target;
    let inputValue = value;
    let isInputValid = true;
    let isNameValid = false;

    const validationRules = {
      client_id: /^[\w\s-]+$/,
      owner: /^[\w\s-]+$/,
      industry: /^[\w\s-]+$/,
      delivery_poc: /^[\w\s-]+$/,
      mobile_number: /^[6-9]\d{9}$/,
      opportunity_value: /^\d*\.?\d+$/, // Accepts numbers with optional decimal point
    };

    if (inputName === "customer_name" && /^[\w\s-]+$/.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }

    if (inputName === "mobile_number" && (inputValue === "" || !isNaN(inputValue))) {
      if (inputValue.length > 10) inputValue = inputValue.slice(0, 10);
      isNameValid = true;
    }

    if (inputName === "opportunity_value" && (inputValue === "" || !isNaN(inputValue))) {
      isNameValid = true;
    }

    if (inputName === "currency" && /^[\w\s-]+$/.test(inputValue)) {
      isNameValid = true;
    }

    if (inputName === "request_client" && /^[\w\s-]+$/.test(inputValue)) {
      isNameValid = true;
    }

    if (validationRules[inputName]) {
      isInputValid = validationRules[inputName].test(value);
    }

    if (inputName === "values") {
      if (!isNumOnly(value)) {
        isInputValid = false;
      }
      if (isEmpty(value)) {
        isInputValid = true;
      }
    }

    if (isInputValid || isNameValid) {
      setPipelineData({
        ...pipelineData,
        [inputName]: inputValue,
      });

      setValidationErrors((prevErrors) => {
        return {
          ...prevErrors,
          [inputName]: "",
        };
      });
    }
  };

  const dropDownHandlerText = (e, item, name) => {
    setPipelineData({
      ...pipelineData,
      [name]: item.key,
    });
    setValidationErrors((prevErrors) => {
      return {
        ...prevErrors,
        [name]: "",
      };
    });
    if (name === "status") {
      setShowPopup1(true);
    }
  };

  function customSortDropDown(arr) {
    if (!Array.isArray(arr)) {
      return [];
    }
    const specialChars = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
    const special = [];
    const numerical = [];
    const alpha = [];
    const mixedAlphaNumeric = [];
    const validEntries = arr.filter((entry) => typeof entry === "object" && "text" in entry);
    validEntries.forEach((entry) => {
      const text = entry.text.toLowerCase();

      if (/^\d/.test(text)) {
        numerical.push(entry);
      } else if (/^[^\w\s]/.test(text)) {
        special.push(entry);
      } else if (/^[a-zA-ZÀ-ÖØ-öø-ÿ]/.test(text)) {
        alpha.push(entry);
      } else {
        mixedAlphaNumeric.push(entry);
      }
    });
    special.sort((a, b) => specialChars.indexOf(a.text[0]) - specialChars.indexOf(b.text[0]));
    numerical.sort((a, b) => a.text.localeCompare(b.text, undefined, { numeric: true }));
    alpha.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: "base" }));
    mixedAlphaNumeric.sort((a, b) => a.text.localeCompare(b.text));
    return special.concat(numerical, alpha, mixedAlphaNumeric);
  }

  function uploadDocs(files) {
    let data = Object.values(files);
    const formData = new FormData();
    data.map((file) => {
      formData.append("files", file);
    });
    axiosPrivateCall.post("/api/v1/crm/addDocuments", formData).then((res) => {
        let fileuploaded = res.data.documents;
        setPipelineData((prev) => {
          let buffer = { ...prev };
          buffer.documents.push(...fileuploaded);
          return buffer;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function removeUploadedDoc(key) {
    let buffer = [...pipelineData.documents];
    let result = [];
    buffer.map((doc) => {
      if (!(doc.document_name === key)) {
        result.push(doc);
      }
    });
    setPipelineData((prev) => {
      let buffer = { ...prev };
      buffer.documents = [...result];
      return buffer;
    });
  }

  const handlePopupClose = () => {
    const hasValues = Object.values(pipelineData).some((value) => {
      if (typeof value === "string") {
        return value.trim() !== "";
      } else if (Array.isArray(value)) {
        return value.length > 0;
      }
      return false;
    });
    if (hasValues) {
      setShowPopup(true);
    } else {
      setShowPopup(!showPopup);
      setIsModalOpen(!isModalOpen);
    }
  };

  return (
    <div>
      <UploadPopup
        showPopup={showUploadPopup}
        setShowPopup={setShowUploadPopup}
        basicInfo={pipelineData || { documents: [] }}
        setBasicInfo={setPipelineData}
      />
      {
        <Popup
          resetState={() => ""}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }
      <Modal
        scrollableContentClassName={styles.addcandidate_modal_scrollable_content}
        containerClassName={`${
          isModalShrunk ? styles.addcandidate_modal_container_shrunk : styles.addcandidate_modal_container
        }`}
        isOpen={isModalOpen}
      >
        <div className={styles.addcandidate_modal_header_container}>
          <div className={styles.mini_header_tag_container}>
            <p className={styles.mini_title}>Opportunity</p>
          </div>
          <div className={styles.header_tag_expand_close_icon_container}>
            <div className={styles.header_tag_container}>Add Opportunity</div>

            <div className={styles.header_expand_close_icon_container}>
              <div onClick={handlePopupClose} className={styles.header_close_icon_container}>
                <Icon iconName="ChromeClose" className={closeIconClass} />
              </div>
            </div>
          </div>

          <div className={styles.dropdownRow}>
            <p className={styles.leftContainer}>
              <div className={styles.customer_name}>
                <Label className={styles.name_label_style} required>
                  Name
                </Label>
                <TextField
                  value={pipelineData.name}
                  placeholder="Enter the Name"
                  onChange={(e) => {
                    inputChangeHandler(e, "name");
                    setCurrentHover("");
                  }}
                  styles={(props) =>
                    getTextFieldStyles(
                      "450px", //width
                      "2.5px", //marginTop
                      "450px", //maxwidth
                      props,
                      currentHover,
                      validationErrors.name,
                      pipelineData.name
                    )
                  }
                />
              </div>
            </p>
            <div className={styles.rightContainer}>
              <div className={styles.opportunity_type}>
                <Label className={styles.label_style} required>
                  Level
                </Label>

                <Dropdown
                  options={LevelOption}
                  selectedKey={pipelineData.level}
                  onChange={(e, item) => {
                    dropDownHandlerText(e, item, "level");
                    setCurrentHover("");
                  }}
                  placeholder="Select"
                  notifyOnReselect
                  styles={(props) =>
                    getDropdownStyles(
                      "195px", //width
                      null, //marginTop
                      "195px", //maxwidth
                      props,
                      currentHover,
                      validationErrors.level,
                      pipelineData.level
                    )
                  }
                />
              </div>
              <div className={styles.opportunity_type}>
                <Label className={styles.label_style} required>
                  Status
                </Label>
                <Dropdown
                  options={statusOption}
                  selectedKey={pipelineData.status}
                  onChange={(e, item) => {
                    dropDownHandlerText(e, item, "status");
                    setCurrentHover("");
                  }}
                  placeholder="Select"
                  notifyOnReselect
                  styles={(props) =>
                    getDropdownStyles(
                      "195px", //width
                      null, //marginTop
                      "195px", //maxwidth
                      props,
                      currentHover,
                      validationErrors.status,
                      pipelineData.status
                    )
                  }
                />

                {pipelineData.status && showPopup1 && (
                  <Popup1
                    showPopup1={showPopup1}
                    setShowPopup1={setShowPopup1}
                    isModalOpen={true}
                    setIsModalOpen={() => setShowPopup1(false)}
                    setPopupNotes={setPopupNotes}
                  />
                )}
              </div>
              <PrimaryButton
                text="Save & Close"
                onClick={submitHandler}
                iconProps={{ iconName: "Save" }}
                styles={saveButtonStyle}
              />
            </div>
          </div>
        </div>

        <div className={styles.add_modal_main_container}>
          <div className={styles.modal_main_container}>
            <div className={styles.sub_container}>
              <div className={styles.opportunity_type_1}>
                <Label className={styles.label_style} required>
                  Request Type
                </Label>

                <Dropdown
                  options={requesttypeOption}
                  selectedKey={pipelineData.request_type}
                  onChange={(e, item) => {
                    dropDownHandlerText(e, item, "request_type");
                    setCurrentHover("");
                  }}
                  placeholder="Select"
                  notifyOnReselect
                  styles={(props) =>
                    getDropdownStyles(
                      "305px", // width
                      null, // marginTop
                      "305px", // maxWidth
                      props,
                      currentHover,
                      validationErrors.request_type,
                      pipelineData.request_type
                    )
                  }
                />
              </div>

              <div className={styles.opportunity_type_1}>
                <Label className={styles.label_style} required>
                  Request Client
                </Label>
                
                <Dropdown
                  options={customSortDropDown(DealName)}
                  selectedKey={pipelineData.request_client}
                  onChange={(e, item) => {
                    dropDownHandlerText(e, item, "request_client");
                    setCurrentHover("");
                  }}
                  placeholder="Select"
                  notifyOnReselect
                  calloutProps={{ 
                    directionalHintFixed: true, 
                    directionalHint: 5  // Position dropdown below the input 
                  }}
                  styles={(props) =>
                    getDropdownStyles(
                      "305px", // width
                      null, // marginTop
                      "305px", // maxWidth
                      props,
                      currentHover,
                      validationErrors.request_client,
                      pipelineData.request_client
                    )
                  }
                />

              </div>
              <div className={styles.opportunity_type_1}>
                <Label className={styles.label_style}>Lead Source</Label>
                <TextField
                  value={pipelineData.lead_source}
                  placeholder="Enter the Lead Source"
                  onChange={(e) => {
                    inputChangeHandler(e, "lead_source");
                    setCurrentHover("");
                  }}
                  styles={(props) =>
                    getTextFieldStyles(
                      null, //width
                      null, //marginTop
                      null, //maxwidth
                      props,
                      currentHover,
                      validationErrors.lead_source,
                      pipelineData.lead_source
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className={styles.container}>
            <div className={styles.box}>
              <div className={styles.top}>
                <div className={styles.portion}>
                  <p className={styles.P_customerDetains}>Customer Details</p>
                  <div className={styles.test}>
                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>Customer Name</Label>
                      <TextField
                        value={pipelineData.customer_name}
                        placeholder="Enter the Name"
                        onChange={(e) => {
                          inputChangeHandler(e, "customer_name");
                          setCurrentHover("");
                        }}
                        styles={(props) =>
                          getTextFieldStyles(
                            null, //width
                            null, //marginTop
                            null, //maxwidth
                            props,
                            currentHover,
                            validationErrors.customer_name,
                            pipelineData.customer_name
                          )
                        }
                      />
                    </div>
                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>Email ID</Label>
                      <TextField
                        value={pipelineData.email_id}
                        placeholder="Enter the Email ID"
                        onChange={(e) => {
                          inputChangeHandler(e, "email_id");
                          setCurrentHover("");
                        }}
                        styles={(props) =>
                          getTextFieldStyles(
                            null, //width
                            null, //marginTop
                            null, //maxwidth
                            props,
                            currentHover,
                            validationErrors.email_id,
                            pipelineData.email_id
                          )
                        }
                      />
                      <p className={styles.errorMessage}>{validationErrors.email_id}</p>
                    </div>
                  </div>
                  <div className={styles.subPortion1}>
                    <Label className={styles.customer_label_style}>Mobile Number</Label>
                    <TextField
                      value={pipelineData.mobile_number}
                      placeholder="Enter the Mobile Number"
                      onChange={(e) => {
                        inputChangeHandler(e, "mobile_number");
                        setCurrentHover("");
                      }}
                      styles={(props) =>
                        getTextFieldStyles(
                          null, //width
                          null, //marginTop
                          null, //maxwidth
                          props,
                          currentHover,
                          validationErrors.mobile_number,
                          pipelineData.mobile_number
                        )
                      }
                    />
                    <p className={styles.errorMessage}>{validationErrors.email_id}</p>
                  </div>
                </div>
              </div>

              <div className={styles.bottom}>
                <div className={styles.portion}>
                  <p className={styles.P_AC}>Assignment & Categorization</p>
                  <div className={styles.test}>
                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>Tech Lead</Label>
                      <TextField
                        value={pipelineData.tech_lead}
                        placeholder="Enter the Tech Lead"
                        onChange={(e) => {
                          inputChangeHandler(e, "tech_lead");
                          setCurrentHover("");
                        }}
                        styles={(props) =>
                          getTextFieldStyles(
                            null, //width
                            null, //marginTop
                            null, //maxwidth
                            props,
                            currentHover,
                            validationErrors.tech_lead,
                            pipelineData.tech_lead
                          )
                        }
                      />
                    </div>

                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>Assigned To</Label>
                      <ComboBox
                        type="text"
                        name="assign_to"
                        inputChangeHandler={inputChangeHandler}
                        setInfo={setPipelineData}
                        setInfoErrors={setBasicInfoErrors}
                        errorMessage={basicInfoerrors.assign_to}
                        dropdown={customSortDropDown(adminSales)}
                        comboStyles={(props) =>
                          getTextFieldStyles(
                            "190px", //width
                            "11px", //marginTop
                            "190px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.assign_to,
                            pipelineData.assign_to
                          )
                        }
                        placeholder="Select"
                      />
                    </div>
                  </div>

                  <div className={styles.test}>
                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>SS Business Unit</Label>

                      <Dropdown
                        options={SSBuisnessUnitOption}
                        selectedKey={pipelineData.ss_businness_unit}
                        onChange={(e, item) => {
                          dropDownHandlerText(e, item, "ss_businness_unit");
                          setCurrentHover("");
                        }}
                        placeholder="Select"
                        notifyOnReselect
                        styles={(props) =>
                          getDropdownStyles(
                            "190px", //width
                            null, //marginTop
                            "190px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.ss_businness_unit,
                            pipelineData.ss_businness_unit
                          )
                        }
                      />
                    </div>

                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>Region & Location</Label>
                      <Dropdown
                        options={customSortDropDown(regionLocationOption)}
                        selectedKey={pipelineData.region_location}
                        onChange={(e, item) => {
                          dropDownHandlerText(e, item, "region_location");
                          setCurrentHover("");
                        }}
                        placeholder="Select"
                        notifyOnReselect
                        styles={(props) =>
                          getDropdownStyles(
                            "190px", //width
                            null, //marginTop
                            "190px", //maxwidth
                            props,
                            currentHover,
                            validationErrors.region_location,
                            pipelineData.region_location
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.box}>
              <div className={styles.top}>
                <div className={styles.portion}>
                  <p className={styles.P_timeline}>Opportunity Timeline</p>
                  <div className={styles.test}>
                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>Start Date</Label>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        onSelectDate={(date) => {
                          dateHandler(date, "start_date");
                        }}
                        styles={calendarClass(pipelineData.start_date)}
                        value={pipelineData.start_date}
                        errorMessage={validationErrors.start_date}
                      />
                    </div>

                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>Close Date</Label>
                      <DatePicker
                        placeholder="DD/MM/YYYY"
                        onSelectDate={(date) => {
                          dateHandler(date, "close_date");
                        }}
                        styles={calendarClass(pipelineData.close_date)}
                        value={pipelineData.close_date}
                        errorMessage={validationErrors.close_date}
                      />
                    </div>
                  </div>

                  <div className={styles.subPortion1}>
                    <Label className={styles.customer_label_style}>Probability of Winning %</Label>
                    <Dropdown
                      options={probOfWinning}
                      selectedKey={pipelineData.probability_winning}
                      onChange={(e, item) => {
                        dropDownHandlerText(e, item, "probability_winning");
                        setCurrentHover("");
                      }}
                      placeholder="Select"
                      notifyOnReselect
                      styles={(props) =>
                        getDropdownStyles(
                          "190px", //width
                          null, //marginTop
                          "190px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.probability_winning,
                          pipelineData.probability_winning
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={styles.bottom}>
                <div className={styles.portion}>
                  <p className={styles.P_Financial}>Financial Details</p>
                  <div className={styles.test}>
                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>Currency</Label>
                      <div className={styles.currency_style}>
                        <ComboBox
                          type="text"
                          name="currency"
                          inputChangeHandler={inputChangeHandler}
                          setInfo={setPipelineData}
                          setInfoErrors={setBasicInfoErrors}
                          value={pipelineData.currency}
                          errorMessage={basicInfoerrors.currency}
                          dropdown={customSortDropDown(currency)}
                          comboStyles={(props) =>
                            getTextFieldStyles(
                              "190px", //width
                              "11px", //marginTop
                              "190px", //maxwidth
                              props,
                              currentHover,
                              validationErrors.currency,
                              pipelineData.currency
                            )
                          }
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div className={styles.subPortion}>
                      <Label className={styles.customer_label_style}>Opportunity Value</Label>

                      <TextField
                        value={pipelineData.opportunity_value}
                        placeholder="Enter the Value"
                        onChange={(e) => {
                          inputChangeHandler(e, "opportunity_value");
                          setCurrentHover("");
                        }}
                        styles={(props) =>
                          getTextFieldStyles(
                            null, //width
                            null, //marginTop
                            null, //maxwidth
                            props,
                            currentHover,
                            validationErrors.opportunity_value,
                            pipelineData.opportunity_value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className={styles.subPortion1}>
                    <Label className={styles.customer_label_style}>Budget Status</Label>
                    <Dropdown
                      options={budgetStatus}
                      selectedKey={pipelineData.budget_status}
                      onChange={(e, item) => {
                        dropDownHandlerText(e, item, "budget_status");
                        setCurrentHover("");
                      }}
                      placeholder="Select"
                      notifyOnReselect
                      styles={(props) =>
                        getDropdownStyles(
                          "190px", //width
                          null, //marginTop
                          "190px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.budget_status,
                          pipelineData.budget_status
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.box}>
              <div className={styles.top1}>
                <p className={styles.P_otherOppurtunity}>Other Opportunity Details</p>
                <div className={styles.customerDetailsContainer}>
                  <div className={styles.textField_customerDetails}>
                    <Label className={styles.customer_label_style}>Priority Level</Label>
                    <Dropdown
                      options={priorityLevel}
                      selectedKey={pipelineData.priority_level}
                      onChange={(e, item) => {
                        dropDownHandlerText(e, item, "priority_level");
                        setCurrentHover("");
                      }}
                      placeholder="Select"
                      notifyOnReselect
                      styles={(props) =>
                        getDropdownStyles(
                          "190px", //width
                          null, //marginTop
                          "190px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.priority_level,
                          pipelineData.priority_level
                        )
                      }
                    />
                  </div>
                  <div className={styles.textField_customerDetails_1}>
                    <Label className={styles.customer_label_style}>Opportunity Source</Label>
                    <Dropdown
                      options={customSortDropDown(opputunitySourceOption)}
                      selectedKey={pipelineData.opportunity_source}
                      onChange={(e, item) => {
                        dropDownHandlerText(e, item, "opportunity_source");
                        setCurrentHover("");
                      }}
                      placeholder="Select"
                      notifyOnReselect
                      styles={(props) =>
                        getDropdownStyles(
                          "190px", //width
                          null, //marginTop
                          "190px", //maxwidth
                          props,
                          currentHover,
                          validationErrors.opportunity_source,
                          pipelineData.opportunity_source
                        )
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={styles.bottom1}>
                <p className={styles.P_customerDetains}>Attachments</p>
                <div className={styles.contentCenter}>
                  <div className={styles.boxWithDottedLines}>
                    <img className={styles.uploadImage} src={uploadImage} />
                    <p className={styles.fileTypeText}>PDF, JPG, PNG, DOC below 1 Mb</p>
                    <p className={styles.infoText}>You can also upload files by</p>
                    <div>
                      <PrimaryButton
                        text="Browse Files"
                        className={styles.uploadButton}
                        styles={buttonStyles}
                        onClick={() => fileInputRef.current.click()}
                      />
                      <input
                        multiple
                        hidden
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => uploadDocs(e.target.files)}
                      />
                    </div>
                    <div></div>
                  </div>
                </div>
                <div className={styles.bottomContainer}>
                  {pipelineData.documents.map((doc, index) => (
                    <div className={styles.spacer} key={index}>
                      <a href={doc.document}>{doc.document_name}</a>
                      <Icon
                        iconName="ChromeClose"
                        className={tableCloseIconClass}
                        onClick={() => {
                          removeUploadedDoc(doc.document_name);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default AddOpportunity;

import { useState, useEffect, useCallback } from "react";
import { Toggle } from "@fluentui/react/lib/Toggle";
import styles from "./AddEmployeeModal.module.css";
import { TextField, PrimaryButton, DatePicker } from "@fluentui/react";
import { Dropdown, DropdownMenuItemType } from "@fluentui/react/lib/Dropdown";
import { mergeStyleSets } from "@fluentui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { generatePassword } from "../utils/generatePassword";
import { axiosPrivateCall } from "../constants";
import { toLowerCaseUnderScore } from "../utils/helpers";
import { Label } from "@fluentui/react/lib/Label";
import { usel } from "@fluentui/react-hooks";
import Editicon from "../assets/editnote.png";
import { useLocation } from "react-router-dom";
import ComboBox from "../components/ComboBox/ComboBox";
import { setIsEdit } from "../redux_library/slice/isDemandEdit.slice";
import { useDispatch, useSelector } from "react-redux";
const nameInputRegex = /^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff]*$/;
const cityRegex = /^[a-zA-Z0-9\s]*$/;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(in|com|org)))$/;
const mobileRegex = /^[6-9]\d{9}$/;
const panRegex = /^([a-zA-Z]([a-zA-Z]([a-zA-Z]([a-zA-Z]([a-zA-Z]([0-9]([0-9]([0-9]([0-9]([a-zA-Z])?)?)?)?)?)?)?)?)?)?$/;
const passRegex = /[A-Za-zÀ-ÖØ-öø-ÿ0-9~`! @#$%^&*()_\-+={[}\]|:;"'<,>.?/)]/;
const addressRegex = /^[a-zA-Z0-9 .,\/\\\-#&:()[\]]*$/;
const pinInputRegex = /^[1-9]{1}[0-9]{0,5}$/;

const calendarClass = (value) => {
  return mergeStyleSets({
    root: {
      "*": {
        fontSize: 13,
        height: "29px !important",
        width: "200px !important",
        lineHeight: "20px !important",
        borderColor: "#E1E5E8 !important",
        borderRadius: "4px",
        lineHeight: "26px !important",
        color: value === 0 ? "LightGray !important" : "LightGray !important",
      },
    },
    field: {
      color: value === 0 ? "LightGray !important" : "LightGray !important",
      fontSize: 12,
      input: {
        color: value === 0 ? "LightGray !important" : "LightGray !important",
        "&::placeholder": {
          color: "LightGray !important",
        },
      },
    },
    icon: {
      height: "14px !important",
      width: "8px !important",
      left: "80%",
      padding: "0px 0px",
      scale: "90%",
    },
    statusMessage: { marginBottom: "-25px" },
  });
};

const dropDownRole = [
  { key: "account_manager", text: "Account Manager" },
  { key: "team_lead", text: "Lead" },
  { key: "recruiter", text: "Recruiter" },
  { key: "recruiter_admin", text: "Rec Admin " },
  { key: "recruitment_bd", text: "Recruitment BD" },
  { key: "sales_admin", text: "Sales Admin" },
  { key: "sales_bd", text: "Sales BD" },
  { key: "super_admin", text: "Super Admin" },
  { key: "uae_admin", text: "UAE Admin" },
];

const dropDownLocation = [
  { key: "Chennai - MEPZ", text: "Chennai - MEPZ" },
  { key: "Chennai - Guindy", text: "Chennai - Guindy" },
  { key: "Chennai - Tidel Park", text: "Chennai - Tidel Park" },
  { key: "Thanjavur", text: "Thanjavur" },
  { key: "UAE", text: "UAE" },
  { key: "Work from home", text: "Work from home" },
];

const dropDownMaritalStatus = [
  { key: "Single", text: "Single" },
  { key: "Married", text: "Married" },
  { key: "Divorced", text: "Divorced" },
  { key: "Widow", text: "Widow" },
  { key: `I don't want to say`, text: `I don't want to say` },
];

const dropDownGender = [
  { key: "Male", text: "Male" },
  { key: "Female", text: "Female" },
  { key: "Others", text: "Others" },
];

const getDropdownStyles = (width, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  const hoverBorderColor = "#E1E5E8";
  let titleColor = value ? "#484848" : error ? "#D24545" : "LightGray";

  const borderRadius = "4px";
  return {
    title: {
      height: "29px",
      width: width,
      display: "flex",
      alignItems: "center",
      color: titleColor,
    },
    dropdown: {
      borderRadius: borderRadius,
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: borderColor,
          borderRadius: borderRadius,
          color: titleColor,
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: hoverBorderColor,
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: borderRadius,
        },
      },
    },
    dropdownItemsWrapper: {
      maxHeight: "350px",
      overflowY: "auto",
    },
  };
};

const getTextFieldStyles = (width, marginTop, maxWidth, props, currentHover, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#D7D7D7";
  }
  return {
    fieldGroup: {
      width: width,
      marginTop: marginTop,
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      borderColor: borderColor,
      borderRadius: "4px",
      boxSizing: "border-box",
      selectors: {
        ":focus": { borderColor: borderColor },
        ":hover": { borderColor: borderColor },
      },
    },
    field: {
      color: "#5B5F62",
      input: {
        color: "red",
        "&::placeholder": {
          color: titleColor,
          fontSize: 12,
        },
      },
    },
    ...(maxWidth && { maxWidth: maxWidth }),
  };
};

const EditEmployee = () => {
  const initialValues = {
    employee_id: "",
    role: "",
    job_role: "",
    status: "",
    reports_to: "",
    location: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    date_of_hire: "",
    date_of_joining: "",
    date_of_birth: "",
    marital_Status: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    gender: "",
    pan_number: "",
    aadhaar_number: "",
    password: "",
    demandCreator: false,
  };

  const [autoGeneratePass, setAutoGeneratePass] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggle, setToggle] = useState(false);
  const [showOtherJobRole, setshowOtherJobRole] = useState(false);
  const [temptextvalue, settempTextValue] = useState("");
  const [employeeData, setEmployeeData] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [currentHover, setCurrentHover] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isManualInput, setIsManualInput] = useState(false);
  const [employeesList, setEmployeesList] = useState([]);
  const [reportsToList, setReportsToList] = useState([]);
  const [view, setView] = useState(true);
  const [edit, setEdit] = useState(false);
  const sanitizeObject = {
    employee_id: "employee_id",
    first_name: "first_name",
    last_name: "last_name",
    email: "email",
    mobile_number: "mobile_number",
    date_of_hire: "date_of_hire",
    date_of_joining: "date_of_joining",
    date_of_birth: "date_of_birth",
    marital_Status: "marital_Status",
    gender: "gender",
    address_line_1: "address_line_1",
    address_line_2: "address_line_2",
    country: "country",
    state: "state",
    city: "city",
    pincode: "pincode",
    pan_number: "pan_number",
    aadhaar_number: "aadhaar_number",
    password: "password_hash",
    role: "role",
    reports_to: "reports_to",
    status: "status",
    job_role: "job_role",
    location: "location",
    demandCreator: "demand_creator",
  };

  useEffect(() => {
    axiosPrivateCall
      .get(`/api/v1/employee/reportstoEmployee`)
      .then((res) => {
        const list = res.data.map((employee) => ({
          key: employee._id,
          text: `${employee.first_name} ${employee.last_name} (${employee.role})`,
        }));
        setReportsToList(list);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  // const onRenderOption = (option) => {
  //   const [name, role] = option.text?.split(" (");
  //   return (
  //     <div>
  //       {name} <span style={{ color: "#D7D7D7" }}>({role}</span>
  //     </div>
  //   );
  // };

  const roleHandler = (event, item) => {
    if (item) {
      const itemKey = String(item.key);
      const updatedRoles = item.selected ? [...employeeData.role, itemKey] : employeeData.role.filter((key) => key !== itemKey);

      console.log(updatedRoles, "updatedRoles");
      setEmployeeData({ ...employeeData, role: updatedRoles });

      dropDownRoleHandler(event, item, "role");
      fieldsSet(event, item);

      setCurrentHover("");
    }
  };

  const dropDownRoleHandler = (e, item, name) => {
    if (name === "role") return;

    setEmployeeData({
      ...employeeData,
      [name]: item.key,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
  };

  const sanitizer = (data) => {
    const sanitizedData = {};
    Object.keys(data).forEach((key) => {
      if (key === "reports_to" && data["role"] === "admin") return;
      if (key === "reports_to" && data["role"] === "account_manager") return;
      sanitizedData[sanitizeObject[key]] = data[key];
    });

    sanitizedData["_id"] = searchParams.get("employee_id");

    return sanitizedData;
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  const sanitizeApiData = (data) => {
    const sanitizedData = {};
    setToggle(data.demand_creator);
    Object.keys(data).forEach((value) => {
      if (value === "reports_to") {
        const reportToObj = {
          key: data["reports_to"]["_id"],
          text: data["reports_to"]["first_name"] + "" + data["reports_to"]["last_name"],
        };

        sanitizedData["reports_to"] = reportToObj.key;
        setReportsToList([reportToObj]);
        return;
      }

      if (getKeyByValue(sanitizeObject, value)) {
        sanitizedData[getKeyByValue(sanitizeObject, value)] = data[value];
      }
    });

    return sanitizedData;
  };

  const getKeyByText = (text) => {
    for (const option of jobRoles) {
      if (option.text === text) {
        return option.key;
      }
    }
    return "";
  };

  const { state } = useLocation();
  const { employeeList } = state || {};

  useEffect(() => {
    if (employeeList) {
      setEmployeeData(employeeList); // Set employee data from passed props
    }
  }, [employeeList]);
  const navigateTo = useNavigate();

  const hoverHandler = (name) => {
    setCurrentHover(name);
  };

  const dropDownHandler = (e, item, name) => {
    setEmployeeData({
      ...employeeData,
      [name]: item.key,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
  };

  const dateHandler = (date, name) => {
    setEmployeeData({
      ...employeeData,
      [name]: date,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
  };


  const inputChangeHandler = (e, name) => {
    let { value } = e.target;
    if (name === "pan_number") {
      value = value.toUpperCase();
    }

    let isNameValid = false;

    if (name === "employee_id" && (value === "" || !isNaN(value))) {
      isNameValid = true;
    } else if (name === "first_name" && nameInputRegex.test(value)) {
      if (value.length > 40) value = value.slice(0, 40);
      isNameValid = true;
    } else if (name === "last_name" && nameInputRegex.test(value)) {
      if (value.length > 40) value = value.slice(0, 40);
      isNameValid = true;
    } else if (name === "email") {
      if (value.length > 320) value = value.slice(0, 320);
      isNameValid = true;
    } else if (name === "mobile_number" && (value === "" || !isNaN(value))) {
      if (value.length > 10) value = value.slice(0, 10);
      isNameValid = true;
    } else if (name === "pan_number") {
      if (panRegex.test(value)) {
        isNameValid = true;
      }
    } else if (name === "aadhaar_number" && (value === "" || !isNaN(value))) {
      if (value.length > 12) value = value.slice(0, 12);
      isNameValid = true;
    } else if (name === "address_line_1" && addressRegex.test(value)) {
      isNameValid = true;
    } else if (name === "address_line_2" && addressRegex.test(value)) {
      isNameValid = true;
    } else if (name === "city" && cityRegex.test(value)) {
      isNameValid = true;
    } else if (name === "pincode" && (value === "" || !isNaN(value)) && (pinInputRegex.test(value) || value === "") && value.length < 7) {
      isNameValid = true;
    } else if (!autoGeneratePass && name === "password" && (passRegex.test(value) || value === "")) {
      if (value.length > 64) {
        value = value.slice(0, 64);
      }
      isNameValid = true;
    }

    if (isNameValid) {
      setEmployeeData({
        ...employeeData,
        [name]: value,
      });
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const employeeDataError = (obj) => {
    const errors = {};
    const { first_name, last_name, email, mobile_number, date_of_joining, job_role, otherJobRole, date_of_hire, pan_number, aadhaar_number, pincode } = obj;

    if (aadhaar_number && aadhaar_number.length != 12) {
      errors.aadhaar_number = "Invalid Adhaar Number";
    }

    if (pincode && pincode.length != 6) {
      errors.pincode = "Invalid Pincode";
    }

    if (!first_name) {
      errors.first_name = " ";
    }

    if (!last_name) {
      errors.last_name = " ";
    }

    if (!email) {
      errors.email = " ";
    } else if (!emailRegex.test(email)) {
      errors.email = "Invalid Email Id";
    }

    if (!mobile_number) {
      errors.mobile_number = " ";
    } else if (!mobileRegex.test(mobile_number)) {
      errors.mobile_number = "Invalid Mobile Number";
    }

    if (pan_number) {
      if (!panRegex.test(pan_number) || pan_number.length !== 10) {
        errors.pan_number = "Invalid PAN Number";
      }
    }

    if (new Date(date_of_hire) > new Date(date_of_joining)) {
      errors.date_of_joining = "Can't be greater than date of hire";
    }

    if (!job_role) {
      errors.job_role = " ";
    } else if (job_role === "Others") {
      if (!otherJobRole) {
        errors.otherJobRole = " ";
      } else if (!nameInputRegex.test(otherJobRole)) {
        errors.otherJobRole = "Invalid name";
      }
    }

    if (errors.job_role === " " && !otherJobRole) {
      errors.otherJobRole = " ";
    }

    if (!job_role) {
      errors.job_role = " ";
    } else if (job_role === "Others") {
      if (!otherJobRole && job_role !== "Others") {
        errors.job_role = " ";
      }

      if (!otherJobRole) {
        errors.otherJobRole = " ";
      } else if (!nameInputRegex.test(otherJobRole)) {
        errors.otherJobRole = "Invalid name";
      }
    }

    return errors;
  };

  const createEmployee = () => {
    const generatedPassword = generatePassword();
    const updatedEmployeeData = {
      ...employeeData,
      password: generatedPassword,
    };

    axiosPrivateCall
      .post("/api/v1/employee/updateEmployee", sanitizer(updatedEmployeeData))
      .then((res) => {
        submitForm();
      })
      .catch((error) => {
        console.log(error);

        if (error.response && error.response.data === "Employee with the same email already exists.") {
          setErrors({
            ...errors,
            email: "Already exist",
          });
        } else {
          setErrors(errors);
        }
      });
  };
 
  function fieldsSet(e, item) {
    if (item.text === "Admin") {
      setEmployeeData((prevState) => {
        return {
          ...prevState,
          reports_to: "NaN",
        };
      });
    } else {
      setEmployeeData((prevState) => {
        return {
          ...prevState,
          reports_to: "",
        };
      });
    }
  }

  function closeHandler() {
    setShowPopup(!showPopup);
  }

  function submitForm() {
    resetState();
    navigateTo("/employee/manageemployee");
  }

  const escKeyHandler = (event) => {
    if (event.key === "Escape") {
      closeHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escKeyHandler, { capture: true });
    return () => {
      document.removeEventListener("keydown", escKeyHandler, { capture: true });
    };
  }, []);

  useEffect(() => {
    if (autoGeneratePass) {
      setEmployeeData((prevState) => {
        return {
          ...prevState,
          password: generatePassword(),
        };
      });
    } else {
      setEmployeeData((prevState) => {
        return {
          ...prevState,
          password: "",
        };
      });
    }
  }, [autoGeneratePass]);
  let minDate = new Date(employeeData.date_of_hire);

  const [jobRoles, getJobRoles] = useState("");

  const getSalesJobRole = async () => {
    return axiosPrivateCall
      .get("api/v1/employee/getSalesJobRole")
      .then((response) => {
        getJobRoles(response.data);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    getSalesJobRole();
  }, []);
  const [isEdit, setIsEditLocal] = useState(false); 
  const dispatch = useDispatch();
  const enableEditButton = () => {
      setIsEditLocal(true);
      dispatch(setIsEdit(true));
      setView(false);
  };
  
  function submitHandler(e) {
    e.preventDefault();
    const errors = employeeDataError(employeeData);
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      createEmployee();
    }
    setIsEditLocal(true);
      dispatch(setIsEdit(true));
  }
    useEffect(() => {
      dispatch(setIsEdit(isEdit));
  }, [isEdit, dispatch]);  

  const comboBoxStyles = {
    root: {
      width: 300,
      height: 29,
      border: "none", // No border at all
      borderRadius: "5px", // Optional: rounded corners
    },
    dropdown: {
      borderRadius: "5px",
      selectors: {
        ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
          borderColor: "#0000FF", // Blue border
          borderRadius: "4px",
          color: "#484848",
        },
        ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
          borderColor: "#0000FF", // Blue border on hover
        },
        ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
          borderRadius: "4px",
        },
      },
    },
    callout: {
      width: 300,
      height: 300,
    },
  };
  const location = useLocation();
  useEffect(() => {
    if (location.state?.editMode) {
      enableEditButton();
    }
  }, [location.state]);
  
  return (
    <div className={styles.pages}>
      <form>
        <div className={styles.addemployee_modal_header_container}>
          <div className={styles.header_tag_expand_close_icon_container}>
            <div className={styles.header_tag_container}>Employee</div>
          </div>

          <div className={styles.header_content_container}>
            <div className={styles.header_content_title_role_container}>
              <div className={styles.header_content_role_save_container}>
                <div className={styles.header_content_title_container}>{isEdit ? "Edit Employee" : "View Employee"} </div>
                <div className={styles.header_employeeid_save_close_container}>
                  <div className={styles.header_save_close_btns_container}>
                    {isEdit ? (
                      <PrimaryButton className={styles.btnStyle} onClick={submitHandler} text="Save & Close" iconProps={{ iconName: "Save" }} />
                    ) : (
                      <div className={styles.header_Edit}>
                        <PrimaryButton onClick={enableEditButton}>
                          <img src={Editicon} alt="image" style={{ marginRight: "5px", width: "16px", height: "16px" }} />
                          <span style={{ fontSize: "16px", fontWeight: "600", paddingBottom: "3px" }}>Edit</span>
                        </PrimaryButton>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.addemployee_modal_main_container}>
          <div className={styles.main_filter_options_container}>
            <div className={styles.subcontainer}>
              <div className={styles.fieldGroups}>
                <Label className={styles.required_field} required>
                  Employee ID
                </Label>
                <div onClick={() => setCurrentHover("employee_id")}>
                  <TextField
                    type="text"
                    placeholder="Enter the Employee ID"
                    name="employee_id"
                    onChange={(e) => inputChangeHandler(e, "employee_id")}
                    value={employeeData.employee_id}
                    errorMessage={errors.employee_id}
                    disabled={view}
                    style={{ backgroundColor: "transparent" }}
                    styles={(props) => getTextFieldStyles("300px", currentHover, errors.employee_id, "employee_id")}
                  />
                </div>
              </div>
            </div>

            <div className={styles.subcontainer}>
              <div className={styles.fieldGroups}>
                <Label className={styles.required_field} required>
                  Role
                </Label>
                <div onClick={() => setCurrentHover("role")}>
                  {view ? (
                    <TextField
                      value={employeeData.role}
                      readOnly
                      styles={(props) => getTextFieldStyles("300px", currentHover, errors.role, employeeData.role)}
                      style={{ backgroundColor: "transparent" }}
                    />
                  ) : (
                    <Dropdown
                      placeholder="Select Role"
                      selectedKeys={employeeData.role}
                      onChange={roleHandler}
                      multiSelect
                      options={dropDownRole}
                      style={{ backgroundColor: "transparent" }}
                      styles={(props) => getDropdownStyles("300px", currentHover, errors.role, employeeData.role)}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.subcontainer}>
              <div className={styles.fieldGroups}>
                <Label className={styles.required_field} required>
                  Designation
                </Label>
                <div onClick={() => setCurrentHover("job_role")}>
                  <TextField
                    type="text"
                    name="job_role"
                    placeholder="Enter the Designation"
                    onChange={(e) => inputChangeHandler(e, "job_role")}
                    value={employeeData.job_role}
                    errorMessage={errors.job_role}
                    disabled={view}
                    style={{ backgroundColor: "transparent" }}
                    styles={(props) => getTextFieldStyles("300px", currentHover, errors.job_role, "job_role")}
                  />
                </div>
              </div>
            </div>
            <div className={styles.subcontainer}>
              <div className={styles.fieldGroups}>
                <Label className={styles.reports_field} required>
                  Reports To
                </Label>
                <div id="reports_to" onClick={() => setCurrentHover("reports_to")}>
                  {view ? (
                    <TextField
                      value={employeeData.reports_to?.first_name + " " + employeeData.reports_to?.last_name}
                      readOnly
                      placeholder="Select"
                      styles={(props) => getTextFieldStyles("300px", currentHover, errors.role, employeeData.role)}
                      style={{ backgroundColor: "transparent" }}
                    />
                  ) : (
                    <ComboBox
                      type="text"
                      name="reports_to"
                      value={employeeData.reports_to?.first_name + " " + employeeData.reports_to?.last_name}
                      inputChangeHandler={(e, item) => {
                        dropDownHandler(e, item, "reports_to");
                      }}
                      dropdown={reportsToList}
                      comboStyles={(props) => getTextFieldStyles("300px", currentHover, errors.reports_to, "reports_to")}
                      setInfo={setEmployeeData}
                      setInfoErrors={setErrors}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.subcontainer}>
              <div className={styles.fieldGroups}>
                <Label className={styles.required_field} required>
                  Reporting Location
                </Label>
                <div id="location" onClick={() => setCurrentHover("location")}>
                  {view ? (
                    <TextField
                      value={employeeData.location}
                      readOnly
                      styles={(props) => getTextFieldStyles("300px", currentHover, errors.role, employeeData.role)}
                      style={{ backgroundColor: "transparent" }}
                    />
                  ) : (
                    <Dropdown
                      placeholder="Select"
                      selectedKey={employeeData.location}
                      onChange={(e, item) => dropDownHandler(e, item, "location")}
                      options={dropDownLocation}
                      disabled={view}
                      style={{ backgroundColor: "transparent" }}
                      styles={(props) => getDropdownStyles("300px", currentHover, errors.location, employeeData.location)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.main_information_container}>
            <div className={styles.left_information_container}>
              <div className={styles.basic_detials_container}>
                <div className={styles.main_basic_information_title}>BASIC INFORMATION</div>

                <div className={styles.main_basic_information_content_container}>
                  <div className={styles.main_sub_from_field}>
                    <div>
                      <Label className={styles.required_field} required>
                        First Name
                      </Label>
                    </div>
                    <div onClick={() => setCurrentHover("first_name")}>
                      <TextField
                        type="text"
                        name="first_name"
                        placeholder="Enter the First Name"
                        onChange={(e) => inputChangeHandler(e, "first_name")}
                        value={employeeData.first_name}
                        errorMessage={errors.first_name}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover, errors.first_name, "first_name")}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field}>
                    <div>
                      <Label className={styles.required_field} required>
                        Last Name
                      </Label>
                    </div>
                    <div onClick={() => setCurrentHover("last_name")}>
                      <TextField
                        type="text"
                        name="last_name"
                        placeholder="Enter the Last Name"
                        onChange={(e) => inputChangeHandler(e, "last_name")}
                        value={employeeData.last_name}
                        errorMessage={errors.last_name}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover, errors.last_name, "last_name")}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field}>
                    <div>
                      <Label className={styles.required_field} required>
                        Email ID
                      </Label>
                    </div>
                    <div onClick={() => setCurrentHover("email")}>
                      <TextField
                        type="text"
                        placeholder="Enter the Email ID"
                        onChange={(e) => inputChangeHandler(e, "email")}
                        value={employeeData.email}
                        errorMessage={errors.email}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover, errors.email, "email")}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field}>
                    <div>
                      <Label className={styles.required_field} required>
                        Mobile Number
                      </Label>
                    </div>
                    <div onClick={() => setCurrentHover("mobile_number")}>
                      <TextField
                        type="text"
                        name="mobile_number"
                        placeholder="Enter the Mobile Number"
                        onChange={(e) => inputChangeHandler(e, "mobile_number")}
                        value={employeeData.mobile_number}
                        errorMessage={errors.mobile_number}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover, errors.mobile_number, "mobile_number")}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field}>
                    <div>
                      <Label className={styles.required_field} required>
                        Date of Hire
                      </Label>
                    </div>
                    <div id="date_of_hire" onClick={() => setCurrentHover("date_of_hire")}>
                      {view ? (
                        <TextField
                          value={employeeData.date_of_hire ? new Date(employeeData.date_of_hire).toLocaleDateString("en-GB") : ""}
                          readOnly
                          styles={(props) => getTextFieldStyles("200px", currentHover, errors.mobile_number, "mobile_number")}
                          style={{ backgroundColor: "transparent" }}
                        />
                      ) : (
                        <DatePicker
                          placeholder="DD/MM/YYYY"
                          onSelectDate={(date) => {
                            dateHandler(date, "date_of_hire");
                            setCurrentHover("");
                          }}
                          value={employeeData.date_of_hire ? new Date(employeeData.date_of_hire) : null}
                          disabled={view}
                          style={{ backgroundColor: "transparent" }}
                          styles={(props) => calendarClass(employeeData.date_of_hire)}
                        />
                      )}
                    </div>
                    <div className={styles.errorfield}>{errors.date_of_hire}</div>
                  </div>

                  <div className={styles.main_sub_from_field}>
                    <div>
                      <Label className={styles.required_field} required>
                        Date of Joining
                      </Label>
                    </div>
                    <div id="date_of_joining" onClick={() => setCurrentHover("date_of_joining")}>
                      {view ? (
                        <TextField
                          value={employeeData.date_of_joining ? new Date(employeeData.date_of_joining).toLocaleDateString("en-GB") : ""}
                          readOnly
                          styles={(props) => getTextFieldStyles("200px", currentHover, errors.mobile_number, "mobile_number")}
                          style={{ backgroundColor: "transparent" }}
                        />
                      ) : (
                        <DatePicker
                          placeholder="DD/MM/YYYY"
                          onSelectDate={(date) => {
                            dateHandler(date, "date_of_joining");
                            setCurrentHover("");
                          }}
                          value={employeeData.date_of_joining ? new Date(employeeData.date_of_joining) : null}
                          disabled={view}
                          style={{ backgroundColor: "transparent" }}
                          styles={(props) => calendarClass(employeeData.date_of_joining)}
                        />
                      )}
                    </div>
                    <div className={styles.errorfield}>{errors.date_of_joining}</div>
                  </div>
                </div>
              </div>

              <div className={styles.identity_detials_container}>
                <div className={styles.main_identity_information_title}>IDENTITY INFORMATION</div>

                <div className={styles.main_basic_information_content_container}>
                  <div className={styles.main_sub_from_field}>
                    <div>
                      <Label className={styles.required_field}>PAN Number</Label>
                    </div>
                    <div onClick={() => setCurrentHover("pan_number")}>
                      <TextField
                        type="text"
                        name="pan_number"
                        placeholder="Enter PAN number"
                        onChange={(e) => inputChangeHandler(e, "pan_number")}
                        value={employeeData.pan_number}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover)}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field}>
                    <div>
                      <Label className={styles.required_field}>Aadhaar Number</Label>
                    </div>
                    <div onClick={() => setCurrentHover("aadhaar_number")}>
                      <TextField
                        type="text"
                        name="aadhaar_number"
                        placeholder="Enter Aadhaar number"
                        onChange={(e) => inputChangeHandler(e, "aadhaar_number")}
                        value={employeeData.aadhaar_number}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.right_information_container}>
              <div className={styles.personal_detials_container}>
                <div className={styles.main_personal_information_title}>PERSONAL DETAILS</div>

                <div className={styles.main_basic_information_content_container}>
                  <div className={styles.main_sub_from_field_gender}>
                    <div>
                      <Label className={styles.required_field}>Date of Birth</Label>
                    </div>
                    <div id="date_of_birth" onClick={() => setCurrentHover("date_of_birth")}>
                      {view ? (
                        <TextField
                          value={employeeData.date_of_birth ? new Date(employeeData.date_of_birth).toLocaleDateString("en-GB") : ""}
                          readOnly
                          styles={(props) => calendarClass("200px", currentHover, errors.mobile_number, "mobile_number")}
                          placeholder="DD/MM/YYYY"
                          style={{ backgroundColor: "transparent" }}
                        />
                      ) : (
                        <DatePicker
                          placeholder="DD/MM/YYYY"
                          onSelectDate={(date) => {
                            dateHandler(date, "date_of_birth");
                            setCurrentHover("");
                          }}
                          value={employeeData.date_of_birth ? new Date(employeeData.date_of_birth) : null}
                          disabled={view}
                          style={{ backgroundColor: "transparent" }}
                          styles={(props) => calendarClass(employeeData.date_of_birth)}
                        />
                      )}
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field_gender}>
                    <div>
                      <Label className={styles.required_field}>Marital Status</Label>
                    </div>
                    <div id="marital_Status" onClick={() => setCurrentHover("marital_Status")}>
                      {view ? (
                        <TextField
                          value={employeeData.marital_Status}
                          readOnly
                          styles={(props) => getTextFieldStyles("200px", currentHover, errors.role, employeeData.role)}
                          placeholder="Select"
                          style={{ backgroundColor: "transparent" }}
                        />
                      ) : (
                        <Dropdown
                          placeholder="Select"
                          selectedKey={employeeData.marital_Status}
                          onChange={(e, item) => dropDownHandler(e, item, "marital_Status")}
                          options={dropDownMaritalStatus}
                          disabled={view}
                          style={{ backgroundColor: "transparent" }}
                          styles={(props) => getDropdownStyles("200px", currentHover, errors.marital_Status, employeeData.marital_Status)}
                        />
                      )}
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field_gender}>
                    <div>
                      <Label className={styles.required_field}>Gender</Label>
                    </div>
                    <div id="gender" onClick={() => setCurrentHover("gender")}>
                      {view ? (
                        <TextField
                          value={employeeData.gender}
                          readOnly
                          styles={(props) => getTextFieldStyles("200px", currentHover, errors.role, employeeData.role)}
                          placeholder="Select"
                          style={{ backgroundColor: "transparent" }}
                        />
                      ) : (
                        <Dropdown
                          placeholder="Select"
                          selectedKey={employeeData.gender}
                          onChange={(e, item) => dropDownHandler(e, item, "gender")}
                          options={dropDownGender}
                          disabled={view}
                          style={{ backgroundColor: "transparent" }}
                          styles={(props) => getDropdownStyles("200px", currentHover, errors.gender, employeeData.gender)}
                        />
                      )}
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field_gender}>
                    <div>
                      <Label className={styles.required_field}>Address Line 1</Label>
                    </div>
                    <div onClick={() => setCurrentHover("address_line_1")}>
                      <TextField
                        type="text"
                        maxLength={50}
                        name="address_line_1"
                        placeholder="Enter your Address"
                        onChange={(e) => inputChangeHandler(e, "address_line_1")}
                        value={employeeData.address_line_1}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("630px", currentHover)}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field_gender}>
                    <div>
                      <Label className={styles.required_field}>Address Line 2</Label>
                    </div>
                    <div onClick={() => setCurrentHover("address_line_2")}>
                      <TextField
                        type="text"
                        maxLength={50}
                        name="address_line_2"
                        placeholder="Enter your Address"
                        onChange={(e) => inputChangeHandler(e, "address_line_2")}
                        value={employeeData.address_line_2}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("630px", currentHover)}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field_gender}>
                    <div>
                      <Label className={styles.required_field}>Country</Label>
                    </div>
                    <div onClick={() => setCurrentHover("country")}>
                      <TextField
                        type="text"
                        name="country"
                        placeholder="Enter Country"
                        onChange={(e) => inputChangeHandler(e, "country")}
                        value={employeeData.country}
                        errorMessage={errors.country}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover)}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field_gender}>
                    <div>
                      <Label className={styles.required_field}>City</Label>
                    </div>
                    <div onClick={() => setCurrentHover("city")}>
                      <TextField
                        type="text"
                        name="city"
                        placeholder="Enter City"
                        onChange={(e) => inputChangeHandler(e, "city")}
                        value={employeeData.city}
                        errorMessage={errors.city}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover)}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field_gender}>
                    <div>
                      <Label className={styles.required_field}>State</Label>
                    </div>
                    <div onClick={() => setCurrentHover("state")}>
                      <TextField
                        type="text"
                        name="state"
                        placeholder="Enter State"
                        onChange={(e) => inputChangeHandler(e, "state")}
                        value={employeeData.state}
                        errorMessage={errors.state}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover)}
                      />
                    </div>
                  </div>

                  <div className={styles.main_sub_from_field_gender}>
                    <div>
                      <Label className={styles.required_field}>Pincode</Label>
                    </div>
                    <div onClick={() => setCurrentHover("pincode")}>
                      <TextField
                        type="text"
                        placeholder="Enter Pincode"
                        name="pincode"
                        onChange={(e) => inputChangeHandler(e, "pincode")}
                        value={employeeData.pincode}
                        disabled={view}
                        style={{ backgroundColor: "transparent" }}
                        styles={(props) => getTextFieldStyles("200px", currentHover)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );

  function resetState() {
    setEmployeeData(initialValues);
    setErrors({});
    setAutoGeneratePass(false);
    setCurrentHover("");
  }
};
export default EditEmployee;

import React, { useState, useEffect } from 'react';
import styles from "./MatchingProfiles.module.css";
// import { calcTotalExp} from "../utils/helpers";
import NoDataFound from "../assets/Nodatafound.png";
import Nomatchimg from "../assets/no.png"
import { FontIcon, TooltipHost, DirectionalHint, Callout, PrimaryButton } from "@fluentui/react";
import { Icon, SearchBox, mergeStyles, mergeStyleSets } from "@fluentui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { axiosPrivateCall } from "../constants";
const styledCallout = {
  callout: {
    width: 400,
    margin: '0px',
    padding: '2px',
  },
  lengthcallout: (height) => ({
    width: 400,
    height: height, 
    margin: '0px',
    padding: '2px',
  }),
  message: {
    width: 180,
    padding: '10px ',
  },
};
const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'selected':
      return '#0BA20B';
    case 'rejected': 
      return '#DE1414';
    case 'waiting':
      return '#0078D4';
    default:
      return 'black'; 
  }
};
  const searchIcon = { iconName: "Search" }; 
  const searchField = (props, error, value) => {
  const borderColor = error ? "#D24545" : "#E1E5E8";
  let titleColor = "#484848";
  if (!value) {
    titleColor = error ? "#D24545" : "#D7D7D7";
  }
  const borderRadius = "4px";
  return {
    fieldGroup: {
      height: "30px",
      backgroundColor: "#FFFFFF",
      color: titleColor,
      paddingLeft: "20px",
      borderColor: borderColor,
      borderRadius: borderRadius,
      boxSizing: "border-box",
      selectors: {
        ":focus": {
          borderColor: borderColor,
        },
        ":hover": {
          borderColor: borderColor,
        },
      },
    },
    field: {
      color: "#5B5F62",
      input: {
        color: "red",
        "&::placeholder": {
          color: "#5B5F62",
          fontSize: 12,
        },
      },
    },
    icon: {
      left: "8px",
      top: "6px",
      pointerEvents: "none",
      position: "absolute",
      color: "#201F1E",
    },
  };
};
  const initialSortState = null;
  export function calcTotalExp(Arr) {
    let totalMonths = 0;
    if (Array.isArray(Arr) && Arr.length > 0) {
        Arr.map((detail) => {
            if (!detail || !detail.start_date || !detail.end_date) {
                console.error('Missing or invalid data:', detail);
                return; 
            }
            function parseDate(dateString) {
                if (!dateString) return NaN; 
                if (dateString.includes('/')) {
                    let [day, month, year] = dateString.split('/');
                    return new Date(`${year}-${month}-${day}`);
                } else {
                    return new Date(dateString);
                }
            }
            let startDate = parseDate(detail.start_date);
            let endDate = parseDate(detail.end_date);
            if (isNaN(startDate) || isNaN(endDate)) {
                console.error('Invalid date format:', detail.start_date, detail.end_date);
                return; 
            }
            let startYear = startDate.getFullYear();
            let endYear = endDate.getFullYear();
            let startMonth = startDate.getMonth();
            let endMonth = endDate.getMonth();
            totalMonths += (endYear - startYear) * 12 + (endMonth - startMonth);
        });
    } else {
        console.error('Array is either empty or not an array.');
    }
    console.log(`Total months: ${totalMonths}`);
    return totalMonths; 
}
function MatchingProfiles(props) {
  if (Array.isArray(props.linkedData)) {
    const candidateIds = props.linkedData.map(candidate => candidate.CandidateId);
    console.log(candidateIds, "Candidate IDs");
  } else {
    console.log("linkedData is not an array.");
  }
  const [data, setData] = useState([]);
  const [candidateIds, setCandidateIds] = useState([]);
  const [commonIds, setCommonIds] = useState([]);
  const [isPopoutVisible, setPopoutVisible] = useState(null);
  const [statusOpen, setStatusOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState(initialSortState);
  const demandId = localStorage.getItem("demandViewId") || '';
  const [selectedProfiles, setSelectedProfiles] = useState([]); 
  const demand_location = useLocation();
  const getDemandData = demand_location.state.demandData ? demand_location.state.demandData : [];
  const DemandObjId = localStorage.setItem("DemandObjId",getDemandData._id) 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [searchTerm, setSearchTerm] = useState('');
  const filteredData = data.filter((row) => {
    const searchTermIsPercentage = searchTerm.includes('%');
    const matchPercentage = row.matchPercentage ? row.matchPercentage.toString() + '%' : '';
    const primarySkillMatch = row.skillset && row.skillset[0]?.skill.toLowerCase().includes(searchTerm.toLowerCase());
    const secondarySkillMatch = row.skillset && row.skillset[1]?.skill.toLowerCase().includes(searchTerm.toLowerCase());
    return (
      row.CandidateId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.first_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.mobile_number?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (searchTermIsPercentage && matchPercentage.includes(searchTerm)) ||
      primarySkillMatch ||  
      secondarySkillMatch  
    );
  });    
  const navigateTo = useNavigate();
  const task = {
    iconName: 'TaskSolid',
    styles: {
      root: {
        color: selectedProfiles.length >= 1 ? '#FFFFFF' : '#5b5f62'
      }
    }
  };
  const fetchData = async () => {
  const initialData = [
      { createdAt: '2023-10-01T12:34:56Z', CandidateId: 'C1', first_name: 'John' },
      { createdAt: '2023-09-25T08:22:13Z', CandidateId: 'C2', first_name: 'Jane' }
    ];
    setData(initialData);
    setLoading(true);
    try {
      const response = await axiosPrivateCall.get(`api/v1/demand/matchingCandidateProfiles?demandId=${demandId}`);
      console.log(response,'matchingCandidateProfiles')
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSortDate = () => {
    let newSortOrder;
    if (sortOrder === 'asc') {
      newSortOrder = 'desc';  
    } else if (sortOrder === 'desc') {
      newSortOrder = initialSortState;  
    } else {
      newSortOrder = 'asc'; 
    }
    setSortOrder(newSortOrder);
    if (newSortOrder) {
      const sortedData = [...data].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return newSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
      });
      setData(sortedData);
    } else {
      fetchData();  
    }
  };
  useEffect(() => {
    fetchData();  
  }, []);
   const handleCheckboxChange = (candidateId) => {
    setSelectedProfiles((prevSelectedProfiles) => {
      if (prevSelectedProfiles.includes(candidateId)) {
        return prevSelectedProfiles.filter(id => id !== candidateId); 
      } else {
        return [...prevSelectedProfiles, candidateId]; 
      }
    });
  };  
   const handleButtonClick = () => {
    if (selectedProfiles.length >= 10) {
      setLoading(true); 
      setTimeout(() => {
        setLoading(false); 
        setIsModalOpen(true); 
      }, 2000); 
    } else {
      setIsModalOpen(true); 
    }
  };
   const handleCloseModal = () => {
    setIsModalOpen(false); 
  };
   const [sortExperienceOrder, setSortExperienceOrder] = useState(initialSortState);
   const handleSortExperience = () => {
    let newSortExperienceOrder;
    if (sortExperienceOrder === 'asc') {
        newSortExperienceOrder = 'desc';  
    } else if (sortExperienceOrder === 'desc') {
        newSortExperienceOrder = initialSortState;  
    } else {
        newSortExperienceOrder = 'asc'; 
    }
    setSortExperienceOrder(newSortExperienceOrder);

    if (newSortExperienceOrder) {
        const sortedData = [...data].sort((a, b) => {
            const expA = calcTotalExp(a.employment_details);
            const expB = calcTotalExp(b.employment_details);
            return newSortExperienceOrder === 'asc' ? expA - expB : expB - expA;
        });
        setData(sortedData);
    } else {
        fetchData();  
    }
};
  const [sortMatchPercentageOrder, setSortMatchPercentageOrder] = useState(initialSortState);
  const handleSortMatchPercentage = () => {
  let newSortMatchPercentageOrder;
  if (sortMatchPercentageOrder === 'asc') {
    newSortMatchPercentageOrder = 'desc';  
  } else if (sortMatchPercentageOrder === 'desc') {
    newSortMatchPercentageOrder = initialSortState;  
  } else {
    newSortMatchPercentageOrder = 'asc'; 
  }
  setSortMatchPercentageOrder(newSortMatchPercentageOrder);
  if (newSortMatchPercentageOrder) {
    const sortedData = [...data].sort((a, b) => {
      const matchA = parseInt(a.matchPercentage || 0);
      const matchB = parseInt(b.matchPercentage || 0);
      return newSortMatchPercentageOrder === 'asc' ? matchA - matchB : matchB - matchA;
    });
    setData(sortedData);
  } else {
    fetchData();  
  }
}; 
useEffect(() => {
  if (data.length > 0) {
    const ids = data.map(candidate => candidate.CandidateId);
    setCandidateIds(ids);
    console.log(ids, "Extracted Candidate IDs"); 
  }
}, [data]);
useEffect(() => {
  if (candidateIds.length > 0 && Array.isArray(props.linkedData)) {
    const linkedCandidateIds = props.linkedData.map(candidate => candidate.CandidateId);
    const common = candidateIds.filter(id => linkedCandidateIds.includes(id));
    setCommonIds(common);
    console.log(common, "Common Candidate IDs"); 
  }
}, [candidateIds, props.linkedData]);
useEffect(() => {
  fetchData();  
}, []);
  const columns = [
    {
      columnKey: ' ',
      label: ' '
    }, {
        columnKey: ' ',
        label: ' '
    }, {
      columnKey: 'CandidateID',
      label: 'Candidate ID'
    }, {
      columnKey: 'CandidateName',
      label: 'Candidate Name'
    }, {
      columnKey: 'DateofSourcing',
      label:
      (
        <div onClick={handleSortDate} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                Date of Sourcing
                <Icon iconName={
                  sortOrder === 'asc' ? "SortUp" :
                  sortOrder === 'desc' ? "SortDown" :
                  "Sort"
                } className={styles.sortIcon} />
              </div>
      )       
    }, {
      columnKey: 'Mobile',
      label: 'Mobile'
    }, {
      columnKey: 'email',
      label: 'Email ID'
    }, {
      columnKey: 'Recruiter',
      label: 'Recruiter',
    }, {
      columnKey: 'Primary Skill',
      label: 'Primary Skill '
    }, {
      columnKey: 'SecondarySkill',
      label: 'Secondary Skill '
    }, {
      columnKey: 'TotalExperience',
      label:  (
        <div onClick={handleSortExperience} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        Total Experience 
        <Icon iconName={
          sortExperienceOrder === 'asc' ? "SortUp" :
          sortExperienceOrder === 'desc' ? "SortDown" :
          "Sort"
        } className={styles.sortIcon} />
      </div>
      )       
    }, {
      columnKey: 'Resume',
      label: 'Resume',
    }, {
      columnKey: 'Status',
      label: 'Status',
    }, {
        columnKey: 'Matching Percentage',
        label:  (
          <div onClick={handleSortMatchPercentage} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
          Matching Percentage
          <Icon iconName={
            sortMatchPercentageOrder === 'asc' ? "SortUp" :
            sortMatchPercentageOrder === 'desc' ? "SortDown" :
            "Sort"
          } className={styles.sortIcon} />
        </div>
) },
  ];
  const handleSubmit = async () => {
    try {
      const demandId = localStorage.getItem("DemandObjId") || '';
      const candidateObj = {
        DemandId: demandId,
        selectedIds: selectedProfiles,
      };
      const response = await axiosPrivateCall.post(`/api/v1/submission/createbulkSubmission`, candidateObj);
      console.log('Submission success:', response.data);
      setIsModalOpen(false);
      navigateTo("/submission/managesubmissions", { state: { message: 'Submission Added Successfully', messageType: 'success' } });
    } catch (error) {
      console.error('Error submitting profiles:', error);
      navigateTo("/submission/managesubmissions", { state: { message: 'Failed to Add Submission', messageType: 'error' } });
    }
  };  
  const renderWithTooltip = (content = '', fieldName) => {
                if (!content) {
                  return <span>{content}</span>; 
                }
                const shouldShowTooltip = content.length > 14 && ['CandidateName', 'Email', 'Recruiter', 'PrimarySkill', 'SecondarySkill'].includes(fieldName);
                return shouldShowTooltip ? (
                  <TooltipHost
                    content={content} 
                    calloutProps={{ gapSpace: 0, beakWidth: 0 }} 
                    directionalHint={DirectionalHint.topCenter}
                    styles={{ root: { display: 'inline-block' } }}
                  >
                    <span style={{ 
                      display: 'inline-block', 
                      maxWidth: '150px', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                      whiteSpace: 'nowrap' 
                    }}>
                      {content.slice(0, 14) + '...'}
                    </span>
                  </TooltipHost>
                ) : (
                  <span>{content}</span>
                );
              }; 
              const [undoneSubmissionIds, setUndoneSubmissionIds] = useState([]);
              const handleUndoClick = async (submissionId) => {
                console.log('submissionId:', submissionId); 
                try {
                  const payload = {
                    data: [{ _id: submissionId }]
                  };
                  const response = await axiosPrivateCall.post(`/api/v1/submission/deleteSubmissionUndone`, payload);
                  console.log('Undo successful:', response.data);
                } catch (error) {
                  console.error('Error undoing the action:', error);
                }
                setUndoneSubmissionIds((prevState) => [...prevState, submissionId]);
                console.log(`Undo clicked for ${submissionId}`);
              };                           
return (
    <div className={styles.container}>
      <div className={styles.header}>
      <div className={styles.rightSection}>Matching Profiles</div>
      <div className={styles.leftSection}>
      {/* <div className={styles.Report}>View Report</div>
      <div className={styles.warningIcon}>
        <Icon iconName="Warning" />
      </div> */}
      <div className={styles.tooltipWrapper}>
  <PrimaryButton 
    className={styles.iconButton} 
    disabled={selectedProfiles.length < 1} 
    style={{
      backgroundColor: selectedProfiles.length >= 1 ? '#0078D4' : '', 
    }}
    onClick={handleButtonClick}
    iconProps={task}
  >
    <span
      className={styles.submitText}
      style={{
        marginBottom: '1px',
        fontWeight: 500,
        marginLeft: '5px',
        color: selectedProfiles.length >= 1 ? '#FFFFFF' : '#5b5f62' 
      }}
    >
      Submit Profiles ({selectedProfiles.length})
    </span>
  </PrimaryButton>
  {!loading && selectedProfiles.length < 1 && (
  <span className={styles.tooltipText}>
    Please select at least one profile to submit
  </span>
)}
{loading && (
  <div className={styles.spinnerContainer}>
    <div className={styles.spinner}></div>
  </div>
)}
        {isModalOpen && !loading && ( 
          <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
              <div className={styles.modalHeader}>
                <span>Do you want to submit the selected candidate profiles for this demand?</span>
                <button className={styles.closeButton} onClick={handleCloseModal}>×</button>
              </div>
              <div className={styles.modalBody}>
                <p>Please click 'Submit' to confirm, or select 'Cancel' to go back</p>
              </div>
              <div className={styles.modalFooter}>
                <button className={styles.cancelButton} onClick={handleCloseModal}>Cancel</button>
                <button className={styles.submitButton} onClick={handleSubmit}>Submit</button>
              </div>
            </div>
          </div>
        )}
</div>
<SearchBox
  styles={(props) => searchField(props)}
  iconProps={searchIcon}
  className={`${styles.search} ${isModalOpen ? styles.disabledSearch : ''} ${loading ? styles.disabledSearch : ''}`} 
  showIcon
  onChange={(e, newValue) => setSearchTerm(newValue.toLowerCase())} 
/>
    </div>
      </div>
      <div className={styles.tableContainer}>
      <table className={styles.table}>
  <thead>
    <tr>           
      {columns.map((column) => (
        <th key={column.columnKey}>{column.label}</th>
      ))}
    </tr>
  </thead>
  <tbody>
  {!loading && filteredData.length > 0 ? (
    filteredData.map((row, index) => (
      <tr key={index}>
 <td className={styles.table_dataContents}>
  <input
    type="checkbox"
    checked={selectedProfiles.includes(row._id)} 
    onChange={() => handleCheckboxChange(row._id)}
    disabled={(() => {
      if (commonIds.includes(row.CandidateId)) {
        const hasSelectedOrWaitingStatus = row.command.some(
          (statusItem) => statusItem.profile_status === "Selected" || statusItem.profile_status === "Waiting"
        );
        return hasSelectedOrWaitingStatus; 
      }
      return false;
    })()} 
    style={{ cursor: commonIds.includes(row.CandidateId) ? ' ' : 'pointer' }}
  />
</td>
        <td className={styles.table_dataContents}>
  <FontIcon 
    iconName='Info' 
    className={styles.statusIcon} 
    id={`buttonId-${row._id}`}
    onClick={() => {
      if (isPopoutVisible === row._id) {
        setPopoutVisible(null);
      } else {
        setPopoutVisible(row._id);
      }
      setStatusOpen(true);
    }}
  />
 {isPopoutVisible === row._id && (
  row.command.some((statuItem) => statuItem.profile_status !== 'NotProcessed') ? (
    row.command.some((statuItem) => statuItem.submissionId) ? (
      (() => {
        const validRows = row.command.filter(
          (statuItem) => statuItem.profile_status !== 'NotProcessed' && statuItem.submissionId
        );
        const dynamicHeight = Math.min(validRows.length * 40 + 100, 200); 
        return (
          <Callout
            className={validRows.length > 3 ? styledCallout.lengthcallout(dynamicHeight) : styledCallout.callout}
            role="alert"
            target={`#buttonId-${row._id}`}
            onDismiss={() => setPopoutVisible(null)}
          >
            <table className={styles.table}>
              <thead className={styles.tableHead}>
                <tr className={styles.tableRows}>
                  <th className={styles.tableHeader}>Submission Date</th>
                  <th className={styles.tableHeader}>Demand Id</th>
                  <th className={styles.tableHeader}>Client Name</th>
                  <th className={styles.tableHeader}>Undo</th>
                </tr>
              </thead>
              <tbody>
                {validRows.map((statuItem, statIndex) => {
                  const isUndoDisabled = ['Selected', 'Rejected'].includes(statuItem.profile_status) || undoneSubmissionIds.includes(statuItem.submissionId);
                  return (
                    <tr key={statIndex} className={styles.tableRow}>
                      <td className={styles.tableCell}>
                        {new Date(statuItem.createdAt).toLocaleDateString('en-GB')}
                      </td>
                      <td className={styles.tableCell}>
                        <span
                          style={{
                            display: 'inline-block',
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: getStatusColor(statuItem.profile_status),
                            marginRight: '8px',
                          }}
                        ></span>
                        {statuItem.demandId || '-'}
                      </td>
                      <td className={styles.tableCell}>{statuItem.client || '-'}</td>
                      <td className={styles.tableCell}>
                        <FontIcon
                          iconName="Undo"
                          style={{
                            cursor: isUndoDisabled ? ' ' : 'pointer',
                            color: isUndoDisabled ? '#AAAAAA' : 'black',
                          }}
                          onClick={() => {
                            if (!isUndoDisabled) handleUndoClick(statuItem.submissionId);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Callout>
        );
      })()
    ) : (
      <Callout
        className={styledCallout.message}
        role="alert"
        target={`#buttonId-${row._id}`}
        onDismiss={() => setPopoutVisible(null)}
      >
        <div className={styles.message}>Profile not yet Processed</div>
      </Callout>
    )
  ) : (
    <Callout
      className={styledCallout.message}
      role="alert"
      target={`#buttonId-${row._id}`}
      onDismiss={() => setPopoutVisible(null)}
    >
      <div className={styles.message}>Profile not yet Processed</div>
    </Callout>
  )
)}
</td>
        <td
          className={styles.candidateId}
          onClick={() => navigateTo(`/candidatelibrary/viewcandidate?candidate_id=${row._id}`)}
        >
          {row.CandidateId}
        </td>
        <td>{renderWithTooltip(`${row.first_name} ${row.last_name}`, 'CandidateName')}</td> 
        <td>{new Date(row.createdAt).toLocaleDateString('en-GB')}</td>
        <td>{renderWithTooltip(row.mobile_number, 'Mobile')}</td> 
        <td>{renderWithTooltip(row.email, 'Email')}</td> 
        <td>{row.created_by ? renderWithTooltip(`${row.created_by.first_name} ${row.created_by.last_name}`, 'Recruiter') : '-'}</td> 
        <td>{renderWithTooltip(row.skillset[0]?.skill || '-', 'PrimarySkill')}</td> 
        <td>{renderWithTooltip(row.skillset[1]?.skill || '-', 'SecondarySkill')}</td>
        <td>
    {row.total_experience 
        ? row.total_experience 
        : calcTotalExp(row.employment_details) === 0 
            ? "0 Years 0 Months" 
            : `${Math.floor(calcTotalExp(row.employment_details) / 12)} Years ${calcTotalExp(row.employment_details) % 12} Months`
    }
</td>
        <td>
          <a href={row.resume_url} target="_blank" rel="noreferrer">Link</a>
        </td>
        <td>{row.status}</td>
        <td
          className={
            parseInt(row.matchPercentage ?? 0) >= 75
              ? styles.highMatch
              : parseInt(row.matchPercentage ?? 0) >= 45
              ? styles.mediumMatch
              : styles.lowMatch
          }
        >
          {`${row.matchPercentage ?? 0}%`}
        </td>
      </tr>
    ))
  ) : !loading && searchTerm ? ( 
    <tr>
      <td colSpan="13" style={{ textAlign: 'center' }}>
        <img src={Nomatchimg} alt="No Match Found" className={styles.img1} />
      </td>
    </tr>
  ) : !loading && (
    <tr>
      <td colSpan="13" style={{ textAlign: 'center' }}>
        <img src={NoDataFound} alt="No Data" className={styles.img} />
      </td>
    </tr>
  )}
</tbody>
</table>
</div>
    </div>
  );
}
export default MatchingProfiles;
import React,{ useState } from "react";
import NoDataFound from "../assets/Nodatafound.png"
import styles from "./ManageEmployee.module.css"
import {PrimaryButton, SearchBox, FontIcon, mergeStyles, Dropdown } from '@fluentui/react';
import { DefaultButton,Callout,DirectionalHint} from '@fluentui/react';
import { Shimmer} from '@fluentui/react';
import AddDemandModal from "./AddDemandModal";
import AddSubmissionModal from "./AddSubmissionModal";
import AssignDemandModal from "./AssignDemandModal";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { useEffect } from "react";
import { axiosPrivateCall } from "../constants";
import { ISOdateToCustomDate } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClientTrackerPopup } from "../components/ClientTrackerPopup";
import { useSearchParams } from 'react-router-dom';
import Nomatchimg from "../assets/no.png"
import { useLocation } from 'react-router-dom';
import { MatchProfilePopup} from "../components/MatchProfilePopup";
import { Spinner, SpinnerSize } from "@fluentui/react";
import debounce from 'lodash.debounce';
const addIcon = { iconName: 'Add' };
const searchIcon = { iconName: 'Search' };
const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 10px',
    color: '#999DA0',
    cursor: 'pointer',
    userSelect: 'none',
  });
  const iconClass1 = mergeStyles({
    fontSize: 12,
    height: 12,
    width: 12,
    margin: '0 ',
    color: '#999DA0',
    cursor: 'pointer'
  });
const messageBarStyles={
  content:{
      maxWidth: 620,
      minWidth: 450,
  }
}
const calloutBtnStyles = {
    root:{
      border: 'none',
      padding: '0px 10px',
      textAlign: 'left',
      height: '20px',
      cursor: 'pointer'
    }
  }
  const CalloutNameStyles ={
    calloutMain:{
      background: '#EDF2F6',
      padding: '2',
    },
  }
  const dropdownStyles = {
    dropdown: { width: 200 },
  }; 
  const options = [
    { key: 'DemandId', text: 'Demand ID' },
    { key: 'job_title', text: 'Requirement' },
    { key: 'skillset', text: 'Skill' },
  ];
let items = Array(4).fill(null);
function DemandListing() {
    const [showMessageBar,setShowMessageBar] = useState(false);
    const [ showSubStauts, setShowSubStatus] = useState(false);
    const [showProfilePopup,setShowProfilePopup]=useState(true);
    const [matchProfile,setMatchProfile]=useState(false)
    const [matchData,setMatchData]=useState([])
    const location = useLocation();
    const [match, setMatch] = useState(location.state);
    const [isModalOpen , setIsModalOpen] = useState(false);
    const [subIsModalOpen, setSubIsModalOpen] = useState(false);
    const [showAssignDemandmodal, setShowAssignDemandModal] = useState(false);
    const [demandList,setDemandList] = useState([]);
    const [demandId,setDemandId] = useState('');
    const [assignDemandId, setAssignDemandId] = useState();
    const [demandViewId,setDemandViewId] = useState('');
    const [isDataLoaded, setIsDataLoaded]= useState(false);
    const[isUserSearching,setIsUserSearching] = useState(false)
    const [isClientTrackerOpen, setIsClientTrackerOpen] = useState(false)
    const [fetchOptions,setFetchOptions] = useState({
        skip: 0,
        limit: 15,
        sort_field:'updatedAt',
        sort_type:-1,
        search_field: ''
      })
    const [rowId,setRowId] = useState('');
    const [updateCallout,setUpdateCallout] = useState(false);
    const [hasMore,setHasMore] = useState(true)
    const navigateTo = useNavigate();
    const demandCreator = localStorage.getItem("demand_creator")
    const [hoverCallout,setHoverCallout]= useState('');
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get('user_id');
    const start_date = searchParams.get('start_date')
    const end_date = searchParams.get('end_date');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const columns = [
      {
      columnKey: 'Demand ID',
       label: 'Demand ID',
    }, {
      columnKey: 'Requirement',
      label: 'Requirement',
    }, 
    {
      columnKey: 'createdAt',
      icon: `${ fetchOptions.sort_field=== 'createdAt' && fetchOptions.sort_type === 1 ?  'SortUp' : 'SortDown' }`,
      label: 'Received Date',
    }, 
   {
      columnKey: 'Client',
      label: 'Client'
    },
    {
      columnKey: 'Due Date',
      label: 'Due Date'
    }
    ,{
      columnKey: 'Min Experience',
      label: 'Min Experience',
    },{
      columnKey: 'Primary Skill',
      label: 'Primary Skill '
    },{
      columnKey: 'Created By',
      label: 'Created By'
    },{
      columnKey: 'Submission Count',
      label: 'Submission Count'
    },{
      columnKey: 'Status',
      label: 'Status'
    },{
      columnKey: 'More Options',
      label: ' '
  }];
  const DEBOUNCE_DELAY = 500;
  useEffect(() => {
    switch (true) {
      case showMessageBar:
        setTimeout(() => {
          setShowMessageBar(false);
        }, 2000);
        break;
        
      case showSubStauts:
        setTimeout(() => {
          setShowSubStatus(false);
        }, 2000);
        break;
  
      default:
        break;
    }
  }, [showMessageBar, showSubStauts]);
  
    useEffect(()=>{
        getDemandList()
        setHasMore(true)
        setFetchOptions({...fetchOptions,skip: 0,limit: 15})
    },[isModalOpen,fetchOptions.sort_field,fetchOptions.sort_type,showMessageBar])
    useEffect(() => {
      setUpdateCallout(false)
      setMatchProfile(false)
      getDemandList()
      setHasMore(true)
      setFetchOptions({...fetchOptions,skip: 0,limit: 15})
    }, [showAssignDemandmodal,showSubStauts])
    const getDemandList=debounce (()=>{
        setIsDataLoaded(false)
        if (userId) {
          const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
          if (dateRegex.test(start_date)) {
            axiosPrivateCall
            .get(`/api/v1/aggregate/listUserLevelActiveDemands?user_id=${userId}&start_date=${start_date}&end_date=${end_date}`)
            .then((res) => {
              setDemandList(res.data);
              setIsDataLoaded(true);   
            })
            .catch((e) => {
              console.log(e);
            });            
          } else{
            axiosPrivateCall
            .get(`/api/v1/aggregate/listUserLevelActiveDemands?user_id=${userId}`)
            .then((res) => {
              setDemandList(res.data);
              setIsDataLoaded(true);   
            })
            .catch((e) => {
              console.log(e);
            });
          }            
        }  
        else if(match){
          axiosPrivateCall
          .get(`api/v1/candidate/matchDemands?skip=0&limit=15&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
          .then((res) => {
            setMatchData(res.data);
            setIsDataLoaded(true)
          }).catch(err=>console.log('falied'))
        }else {
            axiosPrivateCall
            .get(
              `/api/v1/demand/listDemands?skip=0&limit=15&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`
            )
            .then(async (res) => {
              console.log(res.data)
              const submissioncount = res.data.submissioncount
              setDemandList(res.data);
              setIsDataLoaded(true);
            })
            .catch((e) => {
              console.log(e);
            });
            setShowProfilePopup(true)
        }
    },DEBOUNCE_DELAY)
  const [DropdownSearch, setDropdownSearch] = useState('')
  const [SearchData, setSearchData] = useState('')
  const [searchTerm, setSearchTerm] = React.useState('');
  const [SplitedData, setSplitedData] = useState('')
const [hoverCalloutId, setHoverCalloutId] = useState("");
  const handleDropdownChange = (e,item)=>{
    setDropdownSearch(item.key)
    setSearchTerm('')
  }
    const handleSearchInputChange = (event) => {
      if (!event || !event.target) {
        setSearchTerm('');
        return;
      }
     const { value } = event.target;   
    if (DropdownSearch === 'DemandId' && value && !/^[0-9a-zA-Z]+$/.test(event.target.value)) {
      return;
    }
    setSearchTerm(value);
  };   
  const searchDemandList =debounce( (e) =>{
    const searchValue =  e
    if(searchValue === ''){
      getDemandList();
      return
    }
    setIsDataLoaded(false)
    setIsUserSearching(true)
    setFetchOptions(prevData=>{
      return{
        ...prevData,
        search_field: searchValue
      }
    })
     axiosPrivateCall.get(`/api/v1/demand/searchDemand?skip=0&limit=15&field_name=${DropdownSearch}&field_value=${searchValue}`)
     .then(res=>{
      console.log(res)
      setSearchData(res.data)
      setSplitedData(15)
      setDemandList(res.data.slice(0,15));
      setIsDataLoaded(true)
      setHasMore(true)
     }).catch(e=>{
      console.log(e)
     })
  },DEBOUNCE_DELAY)
    const clearSearchBox= ()=>{
      setIsUserSearching(false)
      setFetchOptions(prevData=>{
        return{
          ...prevData,
          search_field: ''
        }
      })
      setSearchTerm(''); 
      getDemandList();
      setHasMore(true)
    }
    const fetchMoreData = debounce(() => {
      if (isUserSearching) {
          const moreDemands = SearchData.slice(SplitedData, SplitedData + 15);
          setSplitedData(SplitedData + 15);
          if (SplitedData >= SearchData.length) {
              setHasMore(false);
          }
      } else if (match) {
          axiosPrivateCall.get(`/api/v1/candidate/matchDemands?skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
              .then(res => {
                  const moreDemands = res.data;
                  console.log(moreDemands.length);
                  setMatchData([...matchData, ...moreDemands]);
                  if (moreDemands.length < 15 || moreDemands.length === 0) {
                      setHasMore(false);
                  }
                  setFetchOptions(prevState => ({
                      ...prevState,
                      skip: fetchOptions.skip + fetchOptions.limit,
                  }));
              })
              .catch(e => console.log(e));
      } else {
          axiosPrivateCall.get(`/api/v1/demand/listDemands?skip=${fetchOptions.skip + fetchOptions.limit}&limit=${fetchOptions.limit}&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`)
              .then(res => {
                  const moreDemands = res.data;
                  console.log(moreDemands.length);
                  setDemandList([...demandList, ...moreDemands]);
                  if (moreDemands.length < 15 || moreDemands.length === 0) {
                      setHasMore(false);
                  }
                  setFetchOptions(prevState => ({
                      ...prevState,
                      skip: fetchOptions.skip + fetchOptions.limit,
                  }));
              })
              .catch(e => console.log(e));
      }
  }, DEBOUNCE_DELAY);  
    const clickSortHandler =(key)=>{
      if(!isDataLoaded) return;
        if(key==='createdAt'){
         setFetchOptions(
           {...fetchOptions,
             sort_field: key,
             sort_type: fetchOptions.sort_type===-1 ? 1 : -1,
           })}
            }
    const downloadDemands = debounce(() => {
      setLoading(true);
      setTimeout(() => {
        axiosPrivateCall
          .get(`/api/v1/demand/downloadDemands?&sort_field=${fetchOptions.sort_field}&sort_type=${fetchOptions.sort_type}`,{
            responseType: 'blob',
          })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setCompleted(true);
            setTimeout(() => {
              setCompleted(false);
            }, 4000);
            setLoading(false);
          })
          .catch(e => {
            console.log(e);
            setLoading(false);
          });
      }, 1000);
    },DEBOUNCE_DELAY);
    function handleAddSubmision(id,viewId) {
      setDemandId(id);
      setSubIsModalOpen(true);
      setDemandViewId(viewId);
    };
    const assignDemandModalHandler = (_id) =>{
      setShowAssignDemandModal(true)
      setAssignDemandId(_id)
    }
    const addEllipsisToName = (name) => {  
      if(name.length > 14)
      {
        let new_name=name.substring(0,12).padEnd(15,'.')
        return new_name
      }
      else return name;
    };
    const handleNavigation = (demandId) => {
      navigateTo(`/demand/viewandeditdemand?demand_id=${demandId}`, { state: { demand: 'demandListing' } });
    };
    return(
            <div className={styles.page}>
                <div className={styles.container}>
                    {isModalOpen && <AddDemandModal
                     setMatchProfile={setMatchProfile}
                     showMessageBar={showMessageBar} 
                     isModalOpen = {isModalOpen} 
                     setIsModalOpen = {setIsModalOpen}
                    setShowMessageBar={setShowMessageBar} />}
                    {isClientTrackerOpen && <ClientTrackerPopup showPopup={true} setShowPopup={setIsClientTrackerOpen}/>}
                    {showAssignDemandmodal && <AssignDemandModal assignDemandId={assignDemandId} isModalOpen={showAssignDemandmodal}  setIsModalOpen={setShowAssignDemandModal}/>}
                  { subIsModalOpen && <AddSubmissionModal subIsModalOpen = {subIsModalOpen} 
                                  setSubIsModalOpen = {setSubIsModalOpen}
                                  showSubStauts = {showSubStauts} 
                                  setShowSubStatus = {setShowSubStatus}
                                  demandId = {demandId}   
                                  demandViewId = {demandViewId}  />}
                     <div className={styles.nav_container}>
                        <div className={styles.title}>Demand Listing</div>
                        {showMessageBar &&<div >
                            <MessageBar  onDismiss={()=>setShowMessageBar(!showMessageBar)} styles={messageBarStyles}  dismissButtonAriaLabel="Close"  messageBarType={MessageBarType.success}>
                             Demand added successfully
                            </MessageBar>
                        </div>}
                        {showSubStauts &&<div >
                            <MessageBar  onDismiss={()=>setShowSubStatus(!showSubStauts)} styles={messageBarStyles}  dismissButtonAriaLabel="Close"  messageBarType={MessageBarType.success}>
                             Submission added successfully
                            </MessageBar>
                        </div>}
                        {matchProfile&&
                        <div >
                        <MatchProfilePopup showProfilePopup={showProfilePopup} setShowProfilePopup={setShowProfilePopup} state='demand' />
                        </div> }
                        <div className={styles.nav_items}>
                            <Dropdown placeholder='Select Search Field' onChange={handleDropdownChange}  options={options} styles={dropdownStyles}/>
                            <SearchBox onChange={handleSearchInputChange} value={searchTerm} onSearch={(e)=>searchDemandList(e)} disabled={DropdownSearch == ""? true:false}  onClear={clearSearchBox} placeholder=" " iconProps={searchIcon} className={styles.search}  
                            showIcon/>
                            <PrimaryButton style={{display : (demandCreator == "true") ? 'block' : 'none'}} onClick={(e)=>{  setTimeout(()=>setIsModalOpen(!isModalOpen),0); setMatch(false) }} text="Add" iconProps={addIcon}  />
                            {loading ? (<Spinner size={SpinnerSize.medium} className={iconClass} />) : 
                          completed ? (<FontIcon iconName="CheckMark" className={iconClass} />) :
                          (<FontIcon iconName="Download" onClick={downloadDemands} className={iconClass} />)}
                        </div>
                    </div>
                    <div id="scrollableDiv" className={styles.table_container}>
                    <InfiniteScroll
    style={{ overflow: 'visible', height: '100%' }}
    dataLength={match ? matchData.length : demandList.length}
    loader={isDataLoaded && (match ? matchData.length >= 15 : demandList.length >= 15) && <h4>Loading...</h4>}
    hasMore={hasMore}
    next={fetchMoreData}
    scrollableTarget="scrollableDiv"
>
                    <table>
                        <thead className={styles.table_header}>
                            <tr className={styles.table_row}>
                                    {columns.map((column) => 
                                        <th onClick={()=>clickSortHandler(column.columnKey)} className={styles.table_headerContents} key={column.columnKey}>
                                            <div className={styles.table_heading}>
                                                <div>{column.label}</div>
                                                {column?.icon ? <FontIcon iconName={column.icon} className={iconClass1} /> : null}
                                            </div>
                                    </th>)}
                            </tr>
                        </thead>
                          <tbody>
                              {isDataLoaded && (!match?demandList.length === 0:matchData.length===0)? (
                            <tr>
                           <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                              <img src={Nomatchimg} alt="NoMatchimage" width={"180px"} height={"200px"} />
                              </td>
                            </tr>
                         ) : (
                            <>
                              {isDataLoaded && (!match?demandList.length === 0 : matchData.length===0)? (
                              <tr>
                                <td className={styles.table_dataContents1} colSpan="13" style={{ textAlign: "center" }}>
                                  <img src={NoDataFound} alt="NoDataimage" width={"190px"} height={"200px"} />
                                </td>
                            </tr>
                           ) : (
                            <>
                                  {isDataLoaded && (match ?(matchData.map((demand,demand_index) => (
                                    <tr className={styles.table_row} key={demand._id}>
                                      <td onClick={() => navigateTo(`/demand/viewandeditdemand?demand_id=${demand._id}`, { state: { demandData:demand, demandListing: 'demandListing' } })} className={styles.table_dataContents}><span className={styles.custom_link}>{demand.DemandId}</span></td>
                                         <td className={styles.table_dataContents}  
                                          onMouseEnter={()=>setHoverCallout(demand.created_by)} 
                                          onMouseLeave={()=>setHoverCallout('')}  
                                          id={`${demand.created_by?.first_name}_${demand._id}`.replaceAll(" ","_")}>
                                          {addEllipsisToName(demand.job_title)}
                                          {(demand.job_title).length >= 14  && hoverCallout=== demand.created_by && <Callout alignTargetEdge={true} 
                                            isBeakVisible={false} styles={CalloutNameStyles} 
                                            directionalHint={DirectionalHint.bottomLeftEdge} target={`#${demand.created_by?.first_name}_${demand._id}`.replaceAll(" ","_")}>
                                            {demand.job_title}
                                            </Callout>
                                          }
                                        </td>
                                        <td className={styles.table_dataContents}>{ISOdateToCustomDate(demand.createdAt)}</td>
                                         <td className={styles.table_dataContents}>{demand.client}</td>
                                        <td className={styles.table_dataContents}>{ISOdateToCustomDate(demand.due_date)}</td>
                                        <td className={styles.table_dataContents}>{`${Math.floor(demand.minimum_experience/12)} years ${demand.minimum_experience%12} months`}</td>
                                        <td className={styles.table_dataContents}
                                          onMouseEnter={() => setHoverCallout(demand._id)}
                                          onMouseLeave={() => setHoverCallout("")}
                                          id={`primary_skill_${demand._id}`}>
                                          {addEllipsisToName(`${demand.skillset[0]?.skill? demand.skillset[0]?.skill: "-"}`)}
                                          {demand.skillset[0]?.skill?.length >= 14 && hoverCallout === demand._id && <Callout alignTargetEdge={true}
                                            isBeakVisible={false} styles={CalloutNameStyles} 
                                            directionalHint={DirectionalHint.bottomLeftEdge} target={`#primary_skill_${demand._id}`}>
                                            {demand.skillset[0]?.skill}
                                           </Callout>
                                          }
                                        </td>
                                       <td className={styles.table_dataContents1}>{demand.submission_count}</td>
                                       <td className={styles.table_dataContents}>{demand.status}</td>
                                       <td className={styles.table_dataContents}>{demand.created_by?.first_name +" "+demand.created_by?.last_name}</td>
                                        <td className={styles.table_dataContents}>
                                            <div id={`Dp${demand._id}`} onClick={()=>{setRowId(demand._id); setUpdateCallout(true)}} className={styles.moreOptions}>  
                                                <FontIcon iconName='MoreVertical' className={iconClass1}/>
                                                {rowId === demand._id && 
                                                  updateCallout && <Callout gapSpace={0} target={`#Dp${demand._id}`} onDismiss={()=>setRowId('')}
                                                  isBeakVisible={false} directionalHint={DirectionalHint.bottomCenter}>
                                                  <div style={{display:'flex', flexDirection:'column'}}>
                                                <DefaultButton onClick={() => navigateTo(`/demand/viewandeditdemand?demand_id=${demand._id}`, { state: { demandData:demand, demandListing: 'demandListing',
        isEditMode: true } })}text="Edit" styles={calloutBtnStyles}/>
                                                  <DefaultButton onClick= {() => {handleAddSubmision(demand._id,demand.DemandId)}}  text="Add Submission" styles={calloutBtnStyles} />
                                                    <DefaultButton onClick={()=>{assignDemandModalHandler(demand._id);setUpdateCallout(false)}}  text={"Assign Demand"}styles={calloutBtnStyles}/>
                                                  </div>
                                                </Callout>
                                                }
                                          </div> 
                                      </td>
                                    </tr>))):
                                   demandList.map((demand,demand_index) => (
                                    <tr className={styles.table_row} key={demand._id}>
                                    <td onClick={() => navigateTo(`/demand/viewandeditdemand?demand_id=${demand._id}`, { state: { demandData:demand, demandListing: 'demandListing' } })} className={styles.table_dataContents}><span className={styles.custom_link}>{demand.DemandId}</span></td>
                                        <td className={styles.table_dataContents}  
                                          onMouseEnter={()=>setHoverCallout(demand.created_by)} 
                                          onMouseLeave={()=>setHoverCallout('')}  
                                          id={`${demand.created_by?.first_name}_${demand._id}`.replaceAll(" ","_")}>
                                          {addEllipsisToName(demand.job_title)}
                                          {(demand.job_title).length >= 14  && hoverCallout=== demand.created_by && <Callout alignTargetEdge={true} 
                                            isBeakVisible={false} styles={CalloutNameStyles} 
                                            directionalHint={DirectionalHint.bottomLeftEdge} target={`#${demand.created_by?.first_name}_${demand._id}`.replaceAll(" ","_")}>
                                            {demand.job_title}
                                            </Callout>
                                          }
                                        </td>
                                        <td className={styles.table_dataContents}>{ISOdateToCustomDate(demand.createdAt)}</td>
                                        <td
  className={styles.table_dataContents}
  onMouseEnter={() => setHoverCalloutId(demand._id)} 
  onMouseLeave={() => setHoverCalloutId("")}
  id={`demand_${demand._id}`} 
>
  {addEllipsisToName(demand.client)}
  {demand.client.length >= 14 && hoverCalloutId === demand._id && ( 
    <Callout
      alignTargetEdge={true}
      isBeakVisible={false}
      styles={CalloutNameStyles}
      directionalHint={DirectionalHint.bottomLeftEdge}
      target={`#demand_${demand._id}`} 
    >
      {demand.client}
    </Callout>
  )}
</td>
                                        <td className={styles.table_dataContents}>{ISOdateToCustomDate(demand.due_date)}</td>
                                        <td className={styles.table_dataContents}>{`${Math.floor(demand.minimum_experience/12)} years ${demand.minimum_experience%12} months`}</td>
                                        <td className={styles.table_dataContents}
                                          onMouseEnter={() => setHoverCallout(demand._id)}
                                          onMouseLeave={() => setHoverCallout("")}
                                          id={`primary_skill_${demand._id}`}>
                                          {addEllipsisToName(`${demand.skillset[0]?.skill? demand.skillset[0]?.skill: "-"}`)}
                                          {demand.skillset[0]?.skill?.length >= 14 && hoverCallout === demand._id && <Callout alignTargetEdge={true}
                                            isBeakVisible={false} styles={CalloutNameStyles} 
                                            directionalHint={DirectionalHint.bottomLeftEdge} target={`#primary_skill_${demand._id}`}>
                                            {demand.skillset[0]?.skill}
                                           </Callout>
                                          }
                                        </td>
                                        <td className={styles.table_dataContents}>{demand.created_by?.first_name +" "+demand.created_by?.last_name}</td>
                                         <td className={styles.table_dataContents1}>{demand.submission_count}</td>
                                       <td className={styles.table_dataContents}>{demand.status}</td>
                                        <td className={styles.table_dataContents}>
                                          <div id={`Dp${demand._id}`} onClick={()=>{setRowId(demand._id); setUpdateCallout(true)}} className={styles.moreOptions}>
                                              <FontIcon iconName='MoreVertical' className={iconClass1}/>
                                              {rowId === demand._id && 
                                            updateCallout && <Callout gapSpace={0} target={`#Dp${demand._id}`} onDismiss={()=>setRowId('')}
                                              isBeakVisible={false} directionalHint={DirectionalHint.bottomCenter}>
                                                <div style={{display:'flex', flexDirection:'column'}}>
                                                    <DefaultButton  onClick={() => navigateTo(`/demand/viewandeditdemand?demand_id=${demand._id}`, { state: {demandData:demand, demandListing: 'demandListing',
        isEditMode: true } })}text="Edit" styles={calloutBtnStyles}/>
                                                  <DefaultButton onClick= {() => {handleAddSubmision(demand._id,demand.DemandId)}}  text="Add Submission" styles={calloutBtnStyles} />
                                                   <DefaultButton onClick={()=>{assignDemandModalHandler(demand._id);setUpdateCallout(false)}}  text={"Assign Demand"}styles={calloutBtnStyles}/>
                                                </div>
                                              </Callout>
                                               }                                        
                                          </div> 
                                      </td>
                                    </tr>)))}
                                    </>
                                )}
                                    { !isDataLoaded && items.map(employee => (
                                  <tr className={styles.table_row} >
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}><Shimmer/></td>
                                      <td className={styles.table_dataContents}>
                                          <div className={styles.moreOptions} >
                                              <FontIcon iconName='MoreVertical' className={iconClass1}/>
                                          </div>
                                      </td>
                                  </tr>))}
                                  </>
                                  )} 
                            </tbody>
                        </table>
                      </InfiniteScroll>
                    </div>
                </div>
            </div>
       )};
export default DemandListing;
import { useState, useEffect, useCallback } from "react";
import { Modal } from "@fluentui/react";
import ComboBox from "../components/ComboBox/ComboBox";
import styles from "./AddEmployeeModal.module.css";
import { Icon } from "@fluentui/react/lib/Icon";
import { TextField, PrimaryButton, DatePicker } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { mergeStyles, mergeStyleSets } from "@fluentui/react";
import { Popup } from "../components/Popup";
import { axiosPublicCall } from "../constants";
import { axiosPrivateCall, axiosJsonCall } from "../constants";
import { useNavigate } from "react-router-dom";
import { generatePassword } from "../utils/generatePassword";
import { useConst } from "@fluentui/react-hooks";
import { Label } from "@fluentui/react/lib/Label";
import { useDispatch } from "react-redux";

// regex
const nameInputRegex = /^[a-zA-Z\u00c0-\u024f\u1e00-\u1eff ]*$/;
const panInputRegex = /^[a-zA-Z0-9]*$/;
const cityRegex = /^[a-zA-Z0-9\s]*$/;
const passRegex = /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[~`!@#\$%^&*\(\)_\-\+={}\[\]|\\:;"'<,>\.?/À-Ùà-ù]).{8,}$/;
const pinInputRegex = /^[1-9]{1}[0-9]{0,5}$/;
const addressRegex = /^[a-zA-Z0-9 .,\/\\\-#&:()[\]]*$/;
const passwordDigitRegex = /^(?:123|(\d)\1{2})$/;

const closeIconClass = mergeStyles({
  fontSize: 16,
  height: "20px",
  width: "20px",
  cursor: "pointer",
});

const calendarClass = (error, value) => {
  return mergeStyleSets({
    root: {
      "*": {
        fontSize: 13,
        height: "29px !important",
        width: "200px !important",
        lineHeight: "25px !important",
        borderColor: error ? "#D24545 !important" : "#E1E5E8 !important",
        borderRadius: "4px",
        color: value ? "black !important" : "LightGray !important",
      },
    },
    field: {
      fontSize: 12,
      input: {
        "&::placeholder": {
          color: error ? "#D24545 !important" : "#E1E5E8 !important",
        },
      },
    },
    icon: {
      height: "14px !important",
      width: "8px !important",
      left: "80%",
      padding: "0px 0px",
      scale: "90%",
    },
    statusMessage: { marginBottom: "-25px" },
  });
};

const dropDownRole = [
  { key: "account_manager", text: "Account Manager" },
  { key: "team_lead", text: "Lead" },
  { key: "recruiter", text: "Recruiter" },
  { key: "recruiter_admin", text: "Rec Admin " },
  { key: "recruitment_bd", text: "Recruitment BD" },
  { key: "sales_admin", text: "Sales Admin" },
  { key: "sales_bd", text: "Sales BD" },
  { key: "admin", text: "Super Admin" },
  { key: "uae_admin", text: "UAE Admin" },
];

const dropDownLocation = [
  { key: "Chennai - MEPZ", text: "Chennai - MEPZ" },
  { key: "Chennai - Guindy", text: "Chennai - Guindy" },
  { key: "Chennai - Tidel Park", text: "Chennai - Tidel Park" },
  { key: "Thanjavur", text: "Thanjavur" },
  { key: "UAE", text: "UAE" },
  { key: "Work from home", text: "Work from home" },
];

const dropDownMaritalStatus = [
  { key: "Single", text: "Single" },
  { key: "Married", text: "Married" },
  { key: "Divorced", text: "Divorced" },
  { key: "Widow", text: "Widow" },
  { key: `I don't want to day`, text: `I don't want to say` },
];

const dropDownGender = [
  { key: "Male", text: "Male" },
  { key: "Female", text: "Female" },
  { key: "Others", text: "Others" },
];

const AddEmployeeModal = (props) => {
  let fieldRequired = 20;
  const { isModalOpen, setIsModalOpen, isSubmitSuccess, setSubmitSuccess } = props;
  const [isModalShrunk, setIsModalShrunk] = useState(false);
  const [autoGeneratePass, setAutoGeneratePass] = useState(true);
  const [today, setToday] = useState(useConst(new Date(Date.now())));
  const [dropDownCities, setDropDownCities] = useState([]);
  const [mode, modef] = useState([]);
  const [firstName, setFirstName] = useState([]);
  const [checkMail, setCheckMail] = useState([]);
  const [checkName, setCheckName] = useState(1);
  const [checkEmail, setCheckEmail] = useState(0);
  const [employeeList, setEmployeeList] = useState([]);
  const [reportsToList, setReportsToList] = useState([]);
  const initialValues = {
    role: [],
    employee_id: "",
    jobRole: "",
    status: "Active",
    reports_to: "",
    location: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    dateOfHire: "",
    dateOfJoin: "",
    dateOfBirth: "",
    maritalStatus: "",
    address1: "",
    address2: "",
    country: "",
    city: "",
    state: "",
    pincode: "",
    gender: "",
    panNumber: "",
    adhaarNumber: "",
    password: "",
    demandCreator: false,
    created_by: "",
  };

  const sanitizeObject = {
    employee_id: "employee_id",
    firstName: "first_name",
    lastName: "last_name",
    email: "email",
    mobile: "mobile_number",
    dateOfHire: "date_of_hire",
    dateOfJoin: "date_of_joining",
    dateOfBirth: "date_of_birth",
    maritalStatus: "marital_Status",
    gender: "gender",
    address1: "address_line_1",
    address2: "address_line_2",
    country: "country",
    city: "city",
    state: "state",
    pincode: "pincode",
    panNumber: "pan_number",
    adhaarNumber: "aadhaar_number",
    password: "password_hash",
    role: "role",
    reports_to: "reports_to",
    status: "status",
    jobRole: "job_role",
    location: "location",
    created_by: "created_by",
    demandCreator: "demand_creator",
  };

  useEffect(() => {
    axiosJsonCall
      .get("/b/643fa67bebd26539d0ae2903")
      .then((res) => {
        let buffer = res.data.record;
        let dropdown_data = buffer.map((obj) => {
          return { key: obj.name, text: obj.name };
        });
        setDropDownCities(dropdown_data);
      })
      .catch((e) => {});
  }, []);

  useEffect(() => {
    axiosPrivateCall
      .get(`/api/v1/employee/reportstoEmployee`)
      .then((res) => {
        const employees = res.data;
        setEmployeeList(employees);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    const list = employeeList.map((employee) => ({
      key: employee._id,
      text: `${employee.first_name} ${employee.last_name} (${employee.role})`,
    }));
    setReportsToList(list);
  }, [employeeList]);

  const onRenderOption = (option) => {
    if (option && option.text) {
      const [name, role] = option.text.split(" (");
      return (
        <div>
          {name} <span style={{ color: "#D7D7D7" }}>{role}</span>
        </div>
      );
    }
  };

  const roleHandler = (event, item) => {
    if (item) {
      const itemKey = String(item.key);
      const updatedRoles = item.selected ? [...employeeData.role, itemKey] : employeeData.role.filter((key) => key !== itemKey);

      setEmployeeData({ ...employeeData, role: updatedRoles });

      dropDownRoleHandler(event, item, "role");
      fieldsSet(event, item);
      fieldsChecks();
      setCurrentHover("");
    }
    setHasUnsavedChanges(true);
  };

  const dropDownRoleHandler = (e, item, name) => {
    if (name === "role") return;

    setEmployeeData({
      ...employeeData,
      [name]: item.key,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
  };

  const sanitizer = (data) => {
    const sanitizedData = {};
    Object.keys(data).forEach((key) => {
      if (key === "reports_to" && data["role"] === "admin") return;
      if (key === "reports_to" && data["role"] === "account_manager") return;

      sanitizedData[sanitizeObject[key]] = data[key];
    });
    console.log(sanitizedData);

    return sanitizedData;
  };

  const [employeeData, setEmployeeData] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [isSaveVisible, setIsSaveVisible] = useState(false);
  const [currentHover, setCurrentHover] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();

  const hoverHandler = (name) => {
    setCurrentHover(name);
  };

  const dropDownHandler = (e, item, name) => {
    setEmployeeData({
      ...employeeData,
      [name]: item.key,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
    setHasUnsavedChanges(true);
  };

  const dateHandler = (date, name) => {
    setEmployeeData({
      ...employeeData,
      [name]: date,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
    setHasUnsavedChanges(true);
  };

  function isPasswordSameAsName(name, password) {
    const lowerName = name.toLowerCase();
    const lowerPassword = password.toLowerCase();

    return lowerName === lowerPassword && passwordDigitRegex.test(password);
  }
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const inputChangeHandler = (e, name) => {
    const { value } = e.target;
    let inputValue = value;

    let isNameValid = false;

    const email = employeeData.email;

    const capitalizedUsername = email.charAt(0).toUpperCase() + email.slice(1);
    modef(capitalizedUsername);

    const [username] = email.split("@");
    const mailCheck = username.charAt(0).toUpperCase() + username.slice(1);
    setCheckMail(mailCheck + "@123");

    if (name === "employee_id" && (inputValue === "" || !isNaN(inputValue))) {
      isNameValid = true;
    }
    if (name === "firstName" && nameInputRegex.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      setCheckName(inputValue);
      isNameValid = true;
    }

    if (name === "lastName" && nameInputRegex.test(inputValue)) {
      if (inputValue.length > 40) inputValue = inputValue.slice(0, 40);
      isNameValid = true;
    }

    if (name === "jobRole" && nameInputRegex.test(inputValue)) {
      if (inputValue.length > 60) inputValue = inputValue.slice(0, 60);
      isNameValid = true;
    }

    if (name === "email") {
      if (inputValue.length > 320) inputValue = inputValue.slice(0, 320);
      let mail = inputValue.length;
      setCheckEmail(mail);

      isNameValid = true;
    }

    if (name === "mobile" && (inputValue === "" || !isNaN(inputValue))) {
      if (inputValue.length > 10) inputValue = inputValue.slice(0, 10);
      isNameValid = true;
    }

    if (name === "panNumber" && panInputRegex.test(inputValue)) {
      if (inputValue.length > 10) inputValue = inputValue.slice(0, 10);
      isNameValid = true;
    }

    if (name === "adhaarNumber" && (inputValue === "" || !isNaN(inputValue))) {
      if (inputValue.length > 12) inputValue = inputValue.slice(0, 12);
      isNameValid = true;
    }

    if (name === "address1" && addressRegex.test(inputValue)) {
      isNameValid = true;
    }

    if (name === "address2" && addressRegex.test(inputValue)) {
      isNameValid = true;
    }

    if (name === "country" && cityRegex.test(inputValue)) {
      isNameValid = true;
    }

    if (name === "city" && cityRegex.test(inputValue)) {
      isNameValid = true;
    }

    if (name === "state" && cityRegex.test(inputValue)) {
      isNameValid = true;
    }

    if (name === "pincode" && (inputValue === "" || !isNaN(inputValue)) && (pinInputRegex.test(inputValue) || inputValue === "") && inputValue.length < 7) {
      isNameValid = true;
    }

    if (!autoGeneratePass && name === "password" && (!passRegex.test(inputValue) || inputValue.length > 8 || inputValue.length < 64)) {
      if (inputValue.length > 64) {
        inputValue = inputValue.slice(0, 64);
      }

      isNameValid = true;
    }

    if (isNameValid) {
      setEmployeeData({
        ...employeeData,
        [name]: inputValue,
      });

      const first = employeeData.firstName;
      const capitalized = first.charAt(0).toUpperCase() + first.slice(1);
      const fn = capitalized + "@123";
      setFirstName(fn);

      setErrors({
        ...errors,
        [name]: null,
      });
    }
    setHasUnsavedChanges(true);
  };

  function submitHandler(e) {
    e.preventDefault();

    const generatedPassword = generatePassword();
    const updatedEmployeeData = {
      ...employeeData,
      password: generatedPassword,
    };

    const errors = validate(updatedEmployeeData);
    if (!employeeData.dateOfJoin) errors.dateOfJoin = " ";
    if (!employeeData.dateOfHire) errors.dateOfHire = " ";
    if (!employeeData.reports_to) errors.reports_to = " ";
    if (!employeeData.jobRole) errors.jobRole = " ";
    if (!employeeData.role || employeeData.role.length === 0) errors.role = " ";

    if (Object.keys(errors).length === 0) {
      axiosPublicCall
        .post("/api/v1/employee/createEmployee", sanitizer(updatedEmployeeData))
        .then((res) => {
          console.log(res);
          console.log(updatedEmployeeData);
          submitForm();
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status === 403) {
            if (error.response.data === "Employee with the same employee ID already exists.") {
              setErrors({
                ...errors,
                employee_id: "Already exist",
              });
            } else if (error.response.data === "Employee with the same email already exists.") {
              setErrors({
                ...errors,
                email: "Already exist",
              });
            }
          } else {
            setErrors(errors);
          }
        });
    } else {
      setErrors(errors);
    }
  }

  function validate(values) {
    const errors = {};
    const nameInputRegex = /^[a-zA-Z0-9- '\u00c0-\u024f\u1e00-\u1eff]*$/;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+(in|com|org)))$/;
    const mobileRegex = /^[0-9]{10}$/;
    const pinRegex = /^[0-9]{6}$/;
    const adhaarNumberRegex = /^[0-9]{12}$/;
    const panNumberRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

    if (!values.role) {
      errors.role = " ";
    }

    if (!values.location) {
      errors.location = " ";
    }
    if (!values.employee_id) {
      errors.employee_id = "  ";
    }

    if (!values.firstName) {
      errors.firstName = " ";
    } else if (!nameInputRegex.test(values.firstName)) {
      errors.firstName = "Invalid name";
    }

    if (!values.lastName) {
      errors.lastName = " ";
    } else if (!nameInputRegex.test(values.lastName)) {
      errors.lastName = "Invalid name";
    }

    if (errors)
      if (!values.email) {
        errors.email = " ";
      } else if (!emailRegex.test(values.email)) {
        errors.email = "Invalid Email Id";
      }

    if (!values.mobile) {
      errors.mobile = " ";
    } else if (!mobileRegex.test(values.mobile)) {
      errors.mobile = "Invalid Mobile Number";
    }
    if (!values.dateOfHire) {
      errors.dateOfHire = " ";
    }

    if (!values.dateOfJoin) {
      errors.dateOfJoin = " ";
    }

    return errors;
  }

  function fieldsChecks() {
    // let employeeDataArr = Object.values(employeeData);
    // let employeeDataFiltered = employeeDataArr.filter(i => i !== "");
    // if (employeeDataFiltered.length === (fieldRequired - 1))
    // {
    // 	setIsSaveVisible(false);
    // };
  }

  function fieldsSet(e, item) {
    if (item.text === "Admin") {
      setEmployeeData((prevState) => {
        return {
          ...prevState,
          reports_to: "NaN",
        };
      });
    } else {
      setEmployeeData((prevState) => {
        return {
          ...prevState,
          reports_to: "",
        };
      });
    }
  }

  const closeHandler = () => {
    if (hasUnsavedChanges) {
      setShowPopup(true);
    } else {
      setIsModalOpen(false); 
    }
  };

  function submitForm() {
    resetState();
    setSubmitSuccess(true);
    setIsModalOpen(false);
  }

  const escKeyHandler = (event) => {
    if (event.key === "Escape") {
      closeHandler();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escKeyHandler, { capture: true });
    return () => {
      document.removeEventListener("keydown", escKeyHandler, { capture: true });

      console.log("removed key handler");
    };
  }, []);

  useEffect(() => {
    if (autoGeneratePass) {
      setEmployeeData((prevState) => {
        const password = generatePassword();
        return {
          ...prevState,
          password: password,
        };
      });
    } else {
      setEmployeeData((prevState) => {
        return {
          ...prevState,
          password: "",
        };
      });
    }
  }, [autoGeneratePass]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    let base64Url = token.split(".")[1];
    let decodedValue = JSON.parse(window.atob(base64Url));
    setEmployeeData({
      ...employeeData,
      created_by: decodedValue.user_id,
    });
  }, []);

  let minDate = new Date(employeeData.dateOfHire);

  const [jobRoles, getJobRoles] = useState("");
  function customSortDropDown(arr) {
    const specialChars = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
    const special = [];
    const numerical = [];
    const alpha = [];
    const mixedAlphaNumeric = [];

    arr?.forEach((entry) => {
      const text = entry.text.toLowerCase(); 

      if (/^\d/.test(text)) {
        numerical.push(entry);
      } else if (/^[^\w\s]/.test(text)) {
        special.push(entry);
      } else if (/^[a-zA-ZÀ-ÖØ-öø-ÿ]/.test(text)) {
        alpha.push(entry);
      } else {
        mixedAlphaNumeric.push(entry);
      }
    });

    special.sort((a, b) => specialChars.indexOf(a.text[0]) - specialChars.indexOf(b.text[0]));
    numerical.sort((a, b) => a.text.localeCompare(b.text, undefined, { numeric: true }));
    alpha.sort((a, b) => a.text.localeCompare(b.text, undefined, { sensitivity: "base" }));
    mixedAlphaNumeric.sort((a, b) => a.text.localeCompare(b.text));

    return special.concat(numerical, alpha, mixedAlphaNumeric);
  }

  const getTextFieldStyles = (width, currentHover, error, value = "") => {
    const borderColor = error ? "#D24545" : "#E1E5E8";
    let titleColor = value.length ? "#484848" : error ? "#D24545" : "LightGray";

    return {
      fieldGroup: {
        width: width,
        height: "30px",
        backgroundColor: "#FFFFFF",
        borderColor: borderColor,
        borderRadius: "4px",
        boxSizing: "border-box",
        selectors: {
          ":focus": { borderColor: borderColor },
          ":hover": { borderColor: borderColor },
        },
      },
      field: {
        color: "#5B5F62",
        input: {
          "&::placeholder": {
            color: titleColor,
            fontSize: 12,
          },
        },
      },
    };
};

const getDropdownStyles = (width, currentHover, error, value = []) => {
    const borderColor = error ? "#D24545" : "#E1E5E8";
    const hoverBorderColor = "#E1E5E8";
    let titleColor = Array.isArray(value) ? (value.length ? "#484848" : error ? "#D24545" : "LightGray") : (value ? "#484848" : error ? "#D24545" : "LightGray");

    const borderRadius = "4px";
    return {
      title: {
        height: "29px",
        width: width,
        display: "flex",
        alignItems: "center",
        color: titleColor,
      },
      dropdown: {
        borderRadius: borderRadius,
        selectors: {
          ".ms-Dropdown-title, .ms-Dropdown-caretDownWrapper": {
            borderColor: borderColor,
            borderRadius: borderRadius,
            color: titleColor,
          },
          ".ms-Dropdown-title:hover, .ms-Dropdown-caretDownWrapper:hover": {
            borderColor: hoverBorderColor,
          },
          ".ms-Dropdown-title:focus, .ms-Dropdown-title:focus-within": {
            borderRadius: borderRadius,
          },
        },
      },
      dropdownItemsWrapper: {
        maxHeight: "350px",
        overflowY: "auto",
      },
    };
};

  return (
    <div>
      {
        <Popup 
        resetState={resetState}
        showPopup={showPopup} 
        setShowPopup={setShowPopup} 
        isModalOpen={isModalOpen} 
        setIsModalOpen={setIsModalOpen} 
      />
        }

      <Modal
        scrollableContentClassName={styles.addemployee_modal_scrollable_content}
        containerClassName={isModalShrunk ? styles.addemployee_modal_container_shrunk : styles.addemployee_modal_container}
        isOpen={isModalOpen}
      >
        <form>
          <div className={styles.addemployee_modal_header_container}>
            <div className={styles.header_tag_expand_close_icon_container}>
              <div className={styles.header_tag_container}>Employee</div>

              <div className={styles.header_expand_close_icon_container}>
                {/* <div onClick={modalSizeHandler} className={styles.header_expand_icon_container}>
                  {isModalShrunk ? <Icon iconName="FullScreen" className={contractIconClass} /> : <Icon iconName="BackToWindow" className={contractIconClass} />}
                </div> */}

                <div onClick={closeHandler} className={styles.header_close_icon_container}>
                  <Icon iconName="ChromeClose" className={closeIconClass} />
                </div>
              </div>
            </div>

            <div className={styles.header_content_container}>
              <div className={styles.header_content_title_role_container}>
                <div className={styles.header_content_role_save_container}>
                  <div className={styles.header_content_title_container}>Add Employee</div>

                  <div className={styles.header_employeeid_save_close_container}>
                    <div className={styles.header_save_close_btns_container}>
                      <PrimaryButton text={`Save & Close`} iconProps={{ iconName: "Save" }} disabled={isSaveVisible} onClick={submitHandler} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.addemployee_modal_main_container}>
            <div className={styles.main_filter_options_container}>
              <div className={styles.subcontainer}>
                <div className={styles.fieldGroups}>
                  <Label className={styles.required_field} required>
                    Employee ID
                  </Label>
                  <div onClick={() => setCurrentHover("employee_id")}>
                  <TextField
  type="text"
  placeholder="Enter the Employee ID"
  name="employee_id"
  onChange={(e) => {
    inputChangeHandler(e, "employee_id");
    fieldsChecks();
  }}
  value={employeeData.employee_id || ""} 
  errorMessage={errors.employee_id}
  styles={() => getTextFieldStyles("300px", currentHover, errors.employee_id, employeeData.employee_id)}
/>
                  </div>
                </div>
              </div>

              <div className={styles.subcontainer}>
  <div className={styles.fieldGroups}>
    <Label className={styles.required_field} required>
      Role
    </Label>
    <div id="role" onClick={() => hoverHandler("role")}>
      <Dropdown
        placeholder="Select Role"
        selectedKeys={employeeData.role}
        onChange={(e, item) => {roleHandler(e, item, "role");
          fieldsChecks();
          setCurrentHover("");
        }}
        multiSelect
        options={dropDownRole}
        styles={(props) => getDropdownStyles("300px", currentHover, errors.role, employeeData.role)} 
        className={styles.loc_dropdown_location}
      />
    </div>
                  {/* <p>{employeeData.role.join(', ')}</p> */}
                </div>
              </div>

              <div className={styles.subcontainer}>
                <div className={styles.fieldGroups}>
                  <Label className={styles.required_field} required>
                    Designation
                  </Label>

                  <div onClick={() => setCurrentHover("jobRole")}>
                    <TextField
                      type="text"
                      name="jobRole"
                      placeholder="Enter the Designation"
                      onChange={(e) => {
                        inputChangeHandler(e, "jobRole");
                        fieldsChecks();
                      }}
                      value={employeeData.jobRole || ""}
                      errorMessage={errors.jobRole}
                      styles={() => getTextFieldStyles("300px", currentHover, errors.jobRole, employeeData.jobRole)}
                    />
                  </div>
                </div>
              </div>

              <div className={styles.subcontainer}>
                <div className={styles.fieldGroups}>
                  <Label className={styles.reports_field} required>
                    Reports To
                  </Label>
                  <div id="reports_to" onClick={() => hoverHandler("reports_to")}>
                    <ComboBox
                      type="text"
                      name="reports_to"
                      inputChangeHandler={(e, item) => {
                        dropDownHandler(e, item, "reports_to");
                      }}
                      dropdown={reportsToList}
                      onRenderOption={onRenderOption}
                      comboStyles={(props) => getTextFieldStyles("300px", currentHover, errors.reports_to, employeeData.reports_to)}
                      setInfo={setEmployeeData}
                      setInfoErrors={setErrors}
                      placeholder="Select"
                    />
                  </div>
                </div>
              </div>

              <div className={styles.subcontainer}>
                <div className={styles.fieldGroups}>
                  <Label className={styles.required_field} required>
                    Reporting Location
                  </Label>
                  <div id="location" onClick={() => hoverHandler("location")}>
                    <Dropdown
                      placeholder="Select"
                      styles={(props) => getDropdownStyles("300px", currentHover, errors.location, employeeData.location)}
                      options={dropDownLocation}
                      onChange={(e, item) => {
                        dropDownHandler(e, item, "location");
                        fieldsChecks();
                        setCurrentHover("");
                      }}
                      className={styles.loc_dropdown_location}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.main_information_container}>
              <div className={styles.left_information_container}>
                <div className={styles.basic_detials_container}>
                  <div className={styles.main_basic_information_title}>BASIC INFORMATION</div>

                  <div className={styles.main_basic_information_content_container}>
                    <div className={styles.main_sub_from_field}>
                      <div>
                        <Label className={styles.required_field} required>
                          First Name
                        </Label>
                      </div>
                      <div onClick={() => setCurrentHover("firstName")}>
                        <TextField
                          type="text"
                          name="firstName"
                          placeholder="Enter the First Name"
                          onChange={(e) => {
                            inputChangeHandler(e, "firstName");
                            fieldsChecks();
                          }}
                          value={employeeData.firstName || ""}
                          errorMessage={errors.firstName}
                          styles={() => getTextFieldStyles("200px", currentHover, errors.firstName, employeeData.firstName)}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>
                        <Label className={styles.required_field} required>
                          Last Name
                        </Label>
                      </div>
                      <div onClick={() => setCurrentHover("lastName")}>
                        <TextField
                          type="text"
                          name="lastName"
                          placeholder="Enter the Last Name"
                          styles={() => getTextFieldStyles("200px", currentHover, errors.lastName, employeeData.lastName)}
                          onChange={(e) => {
                            inputChangeHandler(e, "lastName");
                            fieldsChecks();
                          }}
                          value={employeeData.lastName || ""}
                          errorMessage={errors.lastName}
                        />
                      </div>
                    </div>
                    <div className={styles.main_sub_from_field}>
                      <div>
                        <Label className={styles.required_field} required>
                          Email ID
                        </Label>
                      </div>
                      <div onClick={() => setCurrentHover("email")}>
                        <TextField
                          type="text"
                          placeholder="Enter the Email ID"
                          errorMessage={errors.email}
                          styles={() => getTextFieldStyles("200px", currentHover, errors.email, employeeData.email)}
                          onChange={(e) => {
                            inputChangeHandler(e, "email");
                            fieldsChecks();
                          }}
                          value={employeeData.email || ""}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>
                        <Label className={styles.required_field} required>
                          Mobile Number
                        </Label>
                      </div>
                      <div onClick={() => setCurrentHover("mobile")}>
                        <TextField
                          type="text"
                          name="mobile"
                          placeholder="Enter the Mobile Number"
                          styles={() => getTextFieldStyles("200px", currentHover, errors.mobile, employeeData.mobile)}
                          errorMessage={errors.mobile}
                          onChange={(e) => {
                            inputChangeHandler(e, "mobile");
                            fieldsChecks();
                          }}
                          value={employeeData.mobile || ""}
                        />
                      </div>
                    </div>
                    <div className={styles.main_sub_from_field}>
                      <div>
                        <Label className={styles.required_field} required>
                          Date of hire
                        </Label>
                      </div>
                      <div id="dateOfHire" onClick={() => hoverHandler("dateOfHire")}>
                      <DatePicker
  placeholder="DD/MM/YYYY"
  onSelectDate={(date) => {
    dateHandler(date, "dateOfHire");
    fieldsChecks();
    setCurrentHover("");
  }}
  styles={() => calendarClass(errors.dateOfHire, employeeData.dateOfHire)}
  value={employeeData.dateOfHire || ""}
  errorMessage={errors.dateOfHire}
/>
                      </div>
                      <div className={styles.errorfield}>{errors.dateOfHire}</div>
                    </div>

                    <div className={styles.main_sub_from_field}>
                      <div>
                        <Label className={styles.required_field} required>
                          Date of Joining
                        </Label>
                      </div>
                      <div id="dateOfJoin" onClick={() => hoverHandler("dateOfJoin")}>
                      <DatePicker
  placeholder="DD/MM/YYYY"
  minDate={minDate}
  onSelectDate={(date) => {
    dateHandler(date, "dateOfJoin");
    fieldsChecks();
    setCurrentHover("");
  }}
  styles={() => calendarClass(errors.dateOfJoin, employeeData.dateOfJoin)}
  value={employeeData.dateOfJoin}
/>
                      </div>
                      <div className={styles.errorfield}>{errors.dateOfJoin}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.identity_detials_container}>
                  <div className={styles.main_identity_information_title}>IDENTITY INFORMATION</div>

                  <div className={styles.main_basic_information_content_container}>
                    <div className={styles.main_from_field}>
                      <div className={styles.main_sub_from_field}>
                        <div>
                          <Label className={styles.required_field}>PAN Number</Label>
                        </div>
                        <div onClick={() => setCurrentHover("panNumber")}>
                          <TextField
                            type="text"
                            name="panNumber"
                            placeholder="Enter PAN number"
                            styles={(props) =>
                              getTextFieldStyles(
                                "200px",
                                currentHover
                                // errors.panNumber,
                                // "panNumber"
                              )
                            }
                            onChange={(e) => {
                              inputChangeHandler(e, "panNumber");
                              fieldsChecks();
                            }}
                            value={employeeData.panNumber}
                            // errorMessage={errors.panNumber}
                          />
                        </div>
                      </div>

                      <div className={styles.main_sub_from_field}>
                        <div>
                          <Label className={styles.required_field}>Aadhaar Number</Label>
                        </div>
                        <div onClick={() => setCurrentHover("adhaarNumber")}>
                          <TextField
                            type="text"
                            placeholder="Enter Aadhaar number"
                            name="adhaarNumber"
                            styles={(props) =>
                              getTextFieldStyles(
                                "200px",
                                currentHover
                                // errors.adhaarNumber,
                                // "adhaarNumber"
                              )
                            }
                            onChange={(e) => {
                              inputChangeHandler(e, "adhaarNumber");
                              fieldsChecks();
                            }}
                            value={employeeData.adhaarNumber}
                            // errorMessage={errors.adhaarNumber}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.right_information_container}>
                <div className={styles.personal_detials_container}>
                  <div className={styles.main_personal_information_title}>PERSONAL DETAILS</div>

                  <div className={styles.main_basic_information_content_container}>
                    <div className={styles.main_sub_from_field_gender}>
                      <div>
                        <Label className={styles.required_field}>Date of Birth</Label>
                      </div>
                      <div id="dateOfBirth" onClick={() => hoverHandler("dateOfBirth")}>
                        <DatePicker
                          placeholder="DD/MM/YYYY"
                          maxDate={today}
                          onSelectDate={(date) => {
                            dateHandler(date, "dateOfBirth");
                            fieldsChecks();
                            setCurrentHover("");
                          }}
                          styles={(props) => calendarClass(employeeData.dateOfBirth)}
                          value={employeeData.dateOfBirth}
                        />
                      </div>
                      <div className={styles.errorfield}>{/* {errors.dateOfBirth} */}</div>
                    </div>

                    <div className={styles.main_sub_from_field_gender}>
                      <div>
                        <Label className={styles.required_field}>Marital Status</Label>
                      </div>
                      <div id="maritalStatus" onClick={() => hoverHandler("maritalStatus")}>
                        <Dropdown
                          placeholder="Select"
                          options={dropDownMaritalStatus}
                          onChange={(e, item) => {
                            dropDownHandler(e, item, "maritalStatus");
                            fieldsChecks();
                            setCurrentHover("");
                          }}
                          // errorMessage={errors.maritalStatus}
                          styles={(props) => getDropdownStyles("200px", currentHover, "", employeeData.maritalStatus)}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field_gender}>
                      <div>
                        <Label className={styles.required_field}>Gender</Label>
                      </div>
                      <div id="gender" onClick={() => hoverHandler("gender")}>
                        <Dropdown
                          placeholder="Select"
                          options={dropDownGender}
                          onChange={(e, item) => {
                            dropDownHandler(e, item, "gender");
                            fieldsChecks();
                            setCurrentHover("");
                          }}
                          // errorMessage={errors.gender}
                          styles={(props) => getDropdownStyles("200px", currentHover, "", employeeData.gender)}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field_gender}>
                      <div>
                        <Label className={styles.required_field}>Address Line 1</Label>
                      </div>
                      <div onClick={() => setCurrentHover("address1")}>
                        <TextField
                          type="text"
                          maxLength={50}
                          name="address1"
                          placeholder="Enter your Address"
                          styles={(props) =>
                            getTextFieldStyles(
                              "630px",
                              currentHover
                              // errors.address1,
                              // "address1"
                            )
                          }
                          onChange={(e) => {
                            inputChangeHandler(e, "address1");
                            fieldsChecks();
                          }}
                          value={employeeData.address1}
                          // errorMessage={errors.address1}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field_gender}>
                      <div>
                        <Label className={styles.required_field}>Address Line 2</Label>
                      </div>
                      <div onClick={() => setCurrentHover("address2")}>
                        <TextField
                          type="text"
                          name="address2"
                          maxLength={50}
                          placeholder="Enter your Address"
                          styles={(props) =>
                            getTextFieldStyles(
                              "630px",
                              currentHover
                              // errors.address2,
                              // "address2"
                            )
                          }
                          onChange={(e) => {
                            inputChangeHandler(e, "address2");
                            fieldsChecks();
                          }}
                          value={employeeData.address2}
                          // errorMessage={errors.address2}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field_gender}>
                      <div>
                        <Label className={styles.required_field}>Country</Label>
                      </div>
                      <div onClick={() => setCurrentHover("country")}>
                        <TextField
                          type="text"
                          name="Country"
                          placeholder="Enter Country"
                          styles={(props) =>
                            getTextFieldStyles(
                              "200px",
                              currentHover
                              // errors.city, "city"
                            )
                          }
                          onChange={(e) => {
                            inputChangeHandler(e, "country");
                            fieldsChecks();
                          }}
                          value={employeeData.country}
                          errorMessage={errors.country}
                        />

                        {/* <ComboBox
              type='text'
              name='city'
              inputChangeHandler={inputChangeHandler}
              setInfo={setEmployeeData}
              setInfoErrors={setErrors}
              value={employeeData.city}
              errorMessage={errors.city}
              dropdown={dropDownCities}
              placeholder='City' /> */}
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field_gender}>
                      <div>
                        <Label className={styles.required_field}>City</Label>
                      </div>
                      <div onClick={() => setCurrentHover("city")}>
                        <TextField
                          type="text"
                          name="city"
                          placeholder="Enter City"
                          styles={(props) =>
                            getTextFieldStyles(
                              "200px",
                              currentHover
                              // errors.city, "city"
                            )
                          }
                          onChange={(e) => {
                            inputChangeHandler(e, "city");
                            fieldsChecks();
                          }}
                          value={employeeData.city}
                          errorMessage={errors.city}
                        />

                        {/* <ComboBox
              type='text'
              name='city'
              inputChangeHandler={inputChangeHandler}
              setInfo={setEmployeeData}
              setInfoErrors={setErrors}
              value={employeeData.city}
              errorMessage={errors.city}
              dropdown={dropDownCities}
              placeholder='City' /> */}
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field_gender}>
                      <div>
                        <Label className={styles.required_field}>State</Label>
                      </div>
                      <div onClick={() => setCurrentHover("state")}>
                        <TextField
                          type="text"
                          name="State"
                          placeholder="Enter State"
                          styles={(props) =>
                            getTextFieldStyles(
                              "200px",
                              currentHover
                              // errors.city, "city"
                            )
                          }
                          onChange={(e) => {
                            inputChangeHandler(e, "state");
                            fieldsChecks();
                          }}
                          value={employeeData.state}
                          errorMessage={errors.state}
                        />
                      </div>
                    </div>

                    <div className={styles.main_sub_from_field_gender}>
                      <div>
                        <Label className={styles.required_field}>Pincode</Label>
                      </div>
                      <div onClick={() => hoverHandler("pincode")}>
                        <TextField
                          type="text"
                          placeholder="Enter Pincode"
                          name="pincode"
                          styles={(props) => getTextFieldStyles("200px", currentHover)}
                          onChange={(e) => {
                            inputChangeHandler(e, "pincode");
                            fieldsChecks();
                          }}
                          value={employeeData.pincode}
                          // errorMessage={errors.pincode}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );

  function resetState() {
    setEmployeeData(initialValues);
    setErrors({});
    setAutoGeneratePass(false);
    fieldRequired = 20;
    // setIsSaveVisible(true);
    setCurrentHover("");
  }

  function passField(props) {
    return {
      fieldGroup: [{ height: "22px", width: "100%", border: "0.5px solid transparent" }],
      field: [{ lineHeight: "24px" }],
      revealButton: [
        {
          backgroundColor: "transparent",
          height: "20px",
          color: "grey",
          "&:hover": {
            backgroundColor: "transparent",
            height: "20px",
            color: "grey",
          },
        },
      ],
    };
  }

  function Field(props) {
    return {
      fieldGroup: [{ height: "22px", width: "100%", border: "0.5px solid transparent" }],
      field: [{ lineHeight: "24px" }],
    };
  }
};
export default AddEmployeeModal;
